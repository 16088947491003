import { useEffect, useState } from "react";
import "../styles/log-book-image.css";
import LogBookImageButtons from "./log-book-image-buttons";
import LogBookImageDetails from "./log-book-image-details";
import LogBookImageNote from "./log-book-image-note";
import LogBookImageTags from "./log-book-image-tags";
import LogBookImageText from "./log-book-image-text";
import ModalDeleteImage from "./modalDeleteImage";

const LogBookImage = ({
  imageInfo,
  setImageId,
  userProjects,
  floorsInfo,
  deleteImages,
  setShowModalDeleteImage,
  showModalDeleteImage,
  user,
  setUserImages,
  setShowModalChangeCategory,
  newStateImage
}) => {
  const [projectName, setProjectName] = useState();
  const [floorName, setFloorName] = useState();

  useEffect(() => {
    const filterProject = userProjects?.filter(
      (project) => project.id === imageInfo?.image?.projectId
    );
    const filterFloor = floorsInfo?.filter(
      (floor) => floor.id === imageInfo?.image?.floorLocationId
    );
    if (filterProject) {
      setProjectName(filterProject[0]?.name);
    }
    if (filterFloor) {
      setFloorName(filterFloor[0]?.name);
    }
  }, [floorsInfo, imageInfo, userProjects]);

  return (
    <div className="flex w-full h-[calc(100vh-75px)]">
      {showModalDeleteImage && (
        <ModalDeleteImage
          showModalDeleteImage={showModalDeleteImage}
          setShowModalDeleteImage={setShowModalDeleteImage}
          imageInfo={imageInfo}
          deleteImages={deleteImages}
        />
      )}
      <div className="w-full h-full">
        <div
          className="w-fit flex items-center log-book-image-back-div cursor-pointer"
          onClick={() => setImageId()}
        >
          <img className="mr-3 mb-1" src="/assets/left.svg" alt="back" />
          <p className="text-back text-colorBgPrimary">Back</p>
        </div>
        <div className="flex justify-center items-center h-[calc(100%-64px)]">
          <div className="log-book-image-div items-center flex justify-center">
            <img
              className="log-book-image"
              src={imageInfo?.image.publicUrl}
              alt={imageInfo?.image.title}
              title={imageInfo?.image.title}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col log-book-image-info-div justify-between">
        <LogBookImageDetails imageInfo={imageInfo} />

        <div className="w-full h-full flex flex-col overflow-y-scroll overflow-styles min-h-[60px]">
          <LogBookImageText
            imageInfo={imageInfo}
            floorName={floorName}
            projectName={projectName}
            user={user}
            setUserImages={setUserImages}
            setShowModalChangeCategory={setShowModalChangeCategory}
            newStateImage={newStateImage}
          />
          <LogBookImageNote imageInfo={imageInfo} />
          <LogBookImageTags imageInfo={imageInfo} />
        </div>
        <LogBookImageButtons
          imageInfo={imageInfo}
          setShowModalDeleteImage={setShowModalDeleteImage}
          userProjects={userProjects}
        />
      </div>
    </div>
  );
};
export default LogBookImage;
