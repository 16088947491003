const LogBookImageDetails = ({ imageInfo }) => {
  return (
    <div className="log-book-image-info-details-div mt-10 pb-8">
      <div className="flex">
        <div className="flex ml-5 mr-4 w-2/12">
          <div className="flex w-12 h-12 justify-center items-center rounded-xl bg-[#F1F3F6]">
            <img className="m-1" src="/assets/image.svg" alt="details" />
          </div>
        </div>
        <div className="flex justify-center flex-col w-10/12">
          <p className="text-large-bold mb-1 w-full text-colorBgPrimary cursor-default">Image details</p>
          <p className="text-xxsmall text-colorBgPrimary cursor-default">{imageInfo?.image?.title}</p>
        </div>
      </div>
    </div>
  );
};

export default LogBookImageDetails;
