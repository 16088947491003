/* eslint-disable no-unused-vars */
import { firebase } from "./firebase";
import {
  getAuth,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import {
  doc,
  query,
  where,
  orderBy,
  collection,
  getFirestore,
  getDocs,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

import {
  get,
  getDatabase,
  query as queryRealTime,
  ref as refRealTime,
} from "firebase/database";

const auth = getAuth();
const dbRealTime = getDatabase();
const db = getFirestore(firebase);

export const logUserDB = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      return "error";
    });
};

export const getUserInfoDB = async (userID) => {
  const querySnapshot = await getDoc(doc(db, `users/${userID}`));
  if (querySnapshot.exists()) {
    return querySnapshot.data();
  } else {
    console.log("No data for this user!");
    return null;
  }
};

export const getUserImagesDB = async (userID) => {
  const querySnapshot = await getDocs(collection(db, `users/${userID}/images`));
  let data = {};
  querySnapshot.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  data = Object.values(data);
  if (data.length === 0) {
    return null;
  } else {
    return data;
  }
};

export const filterImagesDB = async (
  userID,
  statusFilter,
  projectIdFilter,
  floorLocationIdsFilter,
  floorsInfo
) => {
  let q;
  let collectionString = collection(db, `users/${userID}/images`);
  let queryConstraints = [];
  let orderString;
  if (floorLocationIdsFilter.length > 10) {
    orderString = orderBy("floorLocationId");
  } else {
    orderString = orderBy("timestampCreated");
  }

  if (statusFilter?.length === 1) {
    queryConstraints.push(where("status", "==", statusFilter[0]));
  }

  if (statusFilter?.length === 2 && floorLocationIdsFilter?.length < 2) {
    queryConstraints.push(where("status", "in", statusFilter));
  }

  if (floorLocationIdsFilter?.length === 1) {
    queryConstraints.push(
      where("floorLocationId", "==", floorLocationIdsFilter[0])
    );
  }

  if (
    floorLocationIdsFilter?.length > 1 &&
    floorLocationIdsFilter?.length !== floorsInfo.length
  ) {
    if (floorLocationIdsFilter.length <= 10) {
      queryConstraints.push(
        where("floorLocationId", "in", floorLocationIdsFilter)
      );
    } else {
      let floorsNotSelected = [];
      let res = floorsInfo.filter(function (el) {
        return floorLocationIdsFilter.indexOf(el.id) === -1;
      });
      res.map((floor) => {
        return floorsNotSelected.push(floor.id);
      });
      queryConstraints.push(
        where("floorLocationId", "not-in", floorsNotSelected)
      );
    }
  }

  if (projectIdFilter) {
    queryConstraints.push(where("projectId", "==", projectIdFilter));
  }

  q = query(collectionString, ...queryConstraints, orderString);
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

export const getFloorsInfoDB = async () => {
  const querySnapshot = await getDocs(collection(db, `floors`));
  let data = {};
  querySnapshot.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  data = data.floors.floors;
  if (data.length === 0) {
    return null;
  } else {
    return data;
  }
};

export const getProjectsInfoDB = async (userID) => {
  const querySnapshot = await getDocs(
    collection(db, `users/${userID}/projects`)
  );
  let data = {};
  querySnapshot.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  data = Object.values(data);
  data = data.sort((a, b) => a.order - b.order);
  if (data.length === 0) {
    return null;
  } else {
    return data;
  }
};

export const deleteImagesDB = async (user, images) => {
  await Promise.all(
    images.map(async (image) => {
      await deleteDoc(doc(db, `users/${user}/images`, image.id));
    })
  );
};

export const updateUserProfileDB = async (userId, userName, userEmail) => {
  let errorGlobal;
  //update doc
  const docRef = doc(db, "users", userId);
  await updateDoc(docRef, {
    fullName: userName,
    email: userEmail,
  });
  //update displayName
  await updateProfile(auth.currentUser, {
    displayName: userName,
  })
    .then(() => {
      console.log("Display Name updated!");
    })
    .catch((error) => {
      errorGlobal = true;
      console.log(error);
    });
  await updateEmail(auth.currentUser, userEmail)
    .then(() => {
      console.log("Email updated!");
    })
    .catch((error) => {
      errorGlobal = true;
      console.log(error);
    });
  if (!errorGlobal) {
    return true;
  } else {
    return null;
  }
};

export const updateUserPasswordDB = async (userPassword) => {
  let errorGlobal;
  await updatePassword(auth.currentUser, userPassword)
    .then(() => {
      console.log("Password updated!");
    })
    .catch((error) => {
      errorGlobal = true;
      console.log(error);
    });
  if (!errorGlobal) {
    return true;
  } else {
    return null;
  }
};

export const getUserTagsDB = async (userId) => {
  const tags = await get(
    queryRealTime(refRealTime(dbRealTime, "tags/" + userId))
  );
  if (tags.val()) {
    const arraySorted = Object.values(tags.val())?.sort((a, b) =>
      a.toLowerCase() > b?.toLowerCase() ? 1 : -1
    );
    return arraySorted;
  } else {
    return null;
  }
};

export const updateUserStateModalDB = async (userId) => {
  const docRef = doc(db, "users", userId);
  await updateDoc(docRef, {
    showWelcomeModal: false,
  });
};

export const changeStatusDB = async (imagesSelected, newState, userId) => {
  await Promise.all(
    imagesSelected.map(async (image) => {
      const docRef = doc(db, `users/${userId}/images/${image.id}`);
      return await updateDoc(docRef, {
        status: newState,
      });
    })
  );
};
