import { useEffect, useState } from "react";
import "../styles/log-book-image.css";
const LogBookImageNote = ({ imageInfo }) => {
  const [noteColor, setNoteColor] = useState(false);
  const [comment, setComment] = useState();
  useEffect(() => {
    if (imageInfo.image.comment) {
      let commentInArray = imageInfo?.image?.comment.split(" ");
      if (commentInArray.length > 0) {
        // eslint-disable-next-line array-callback-return
        commentInArray.map((word, key) => {
          if (word.indexOf("#") > -1) {
            commentInArray[
              key
            ] = `<span class='text-xxsmall text-colorTextPrimary cursor-default'>${word}</span>`;
          } else {
            commentInArray[
              key
            ] = `<span class='text-xxsmall text-colorBgPrimary cursor-default'>${word}</span>`;
          }
        });
      }
      commentInArray = commentInArray.join(" ");
      setComment(commentInArray);
    }
  }, [imageInfo?.image?.comment]);

  useEffect(() => {
    if (comment) {
      document.getElementById("image-comment-div").innerHTML = comment;
    }
  }, [comment]);

  const showNote = () => {
    navigator.clipboard.writeText(imageInfo?.image?.comment);
    setNoteColor(true);
    setTimeout(() => {
      setNoteColor();
    }, 2500);
  };
  return (
    <div className="pt-5 pb-6 log-book-image-info-note-div">
      <div className="flex justify-between items-center mb-1 h-10">
        <p className="text-xxsmall-bold text-colorBgPrimary cursor-default">Note</p>
        <div
          className="flex items-center py-1 px-2 rounded-lg"
          style={{ background: noteColor && "#F1F3F6" }}
        >
          {noteColor && (
            <div style={{ background: noteColor && "#F1F3F6" }}>
              <p className="log-book-image-note-text text-xsmall-bold text-[#3ECD9A] pt-1 mr-3 ml-2 cursor-default">
                Note copied!
              </p>
            </div>
          )}

          <img
            onClick={showNote}
            className=" cursor-pointer"
            src={noteColor ? "/assets/notes-color.svg" : "/assets/notes.svg"}
            alt="Note"
          />
        </div>
      </div>
      <div id="image-comment-div" className="cursor-default"></div>
      {/* <p className=" pt-2">{imageInfo?.image?.comment}</p> */}
    </div>
  );
};

export default LogBookImageNote;
