/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";

const LogbookHeader = ({
  userInfo,
  setShowUserProfile,
  showUserProfile,
  wrapperRef,
  profileIsClicked,
  setProfileIsClicked,
  setImageId,
}) => {
  const [userName, setUserName] = useState();

  useEffect(() => {
    if (profileIsClicked) {
      document.getElementById("profile-div").style.width = "384px";
      document.getElementById("profile-div").style.opacity = "1";
      document.getElementById("profile-div").style.zIndex = "100";
      // document.getElementById("log-book-checkbox-div").style.border = 0;
      setShowUserProfile(!showUserProfile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIsClicked]);

  useEffect(() => {
    setUserName(userInfo?.fullName?.split(" "));
  }, [userInfo]);

  return (
    <div className="log-book-header w-full flex items-center justify-between absolute">
      <img
        className="cursor-pointer"
        src="/assets/logo-home.svg"
        alt="logo"
        onClick={() => setImageId()}
      />

      {userInfo && (
        <div
          ref={wrapperRef}
          className="log-book-user-info-button flex items-center justify-center w-9 h-9 bg-colorBgPrimary text-colorTextWhite cursor-pointer"
          style={{ border: showUserProfile && "2px solid #3569E3" }}
          onClick={() => {
            setProfileIsClicked(!profileIsClicked);
          }}
        >
          <div className="flex items-center justify-center">
            <span className="text-xsmall-bold pt-0.5">
              {userName?.map((name, key) => {
                if (key < 2) {
                  return name[0]?.toUpperCase();
                }
              })}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogbookHeader;
