/* eslint-disable array-callback-return */
import "../styles/log-book-filters.css";
import LogBookDropdownFloors from "./log-book-dropdown-floors";
import LogBookDropdownHashtags from "./log-book-dropdown-hashtags";
import LogBookDropdownProjects from "./log-book-dropdown-projects";
import LogBookFilterButtons from "./log-book-filter-buttons";
import LogBookStatusFilter from "./log-book-filter-status";
const LogbookFilters = ({
  userProjects,
  floorsInfo,
  setProjectIdFilter,
  setStatusFilter,
  setFloorLocationIdsFilter,
  floorLocationIdsFilter,
  statusFilter,
  projectIdFilter,
  userImages,
  imagesSelectedCheckBox,
  setImagesSelectedCheckBox,
  setShowModalDeleteImage,
  userTags,
  tagsFilter,
  setTagsFilter,
  setProjectNameSelected,
  projectNameSelected,
  showModalDeleteImage,
  setUserImages,
  user,
  setShowModalChangeCategory
}) => {
  const handleChangeSelectAll = (e) => {
    if (e.target.checked) {
      let imageUrls = [];
      userImages?.map((image, key) => {
        imageUrls.push({
          name: image.title,
          url: image.publicUrl,
          id: image.id,
          projectId: image.projectId,
          title: image.title,
          timestampCreated: image.timestampCreated.seconds,
        });
        document.getElementById(
          `log-book-images-item-${key}`
        ).style.opacity = 1;
        document.getElementById(
          `log-book-image-${key}-checkbox`
        ).checked = true;
      });
      setImagesSelectedCheckBox(imageUrls);
    } else {
      userImages?.map((image, key) => {
        document.getElementById(
          `log-book-image-${key}-checkbox`
        ).checked = false;
        document.getElementById(
          `log-book-images-item-${key}`
        ).style.opacity = 0;
      });
      setImagesSelectedCheckBox([]);
    }
  };
  return (
    <div
      className="w-full px-8"
      style={{
        opacity: showModalDeleteImage && "0.25",
        pointerEvents: showModalDeleteImage && "none",
      }}
    >
      <div className="flex w-full items-start dropdowns-div z-10">
        <div className="flex items-start w-full ">
          <div className="w-2/12 min-w-[calc(134px)] z-10">
            <LogBookDropdownProjects
              infoArray={userProjects}
              setFilter={setProjectIdFilter}
              filter={projectIdFilter}
              setImagesSelectedCheckBox={setImagesSelectedCheckBox}
              setProjectNameSelected={setProjectNameSelected}
              projectNameSelected={projectNameSelected}
            />
          </div>
          <div className="w-2/12 min-w-[calc(134px)] z-10">
            <LogBookDropdownFloors
              infoArray={floorsInfo}
              setFilter={setFloorLocationIdsFilter}
              filter={floorLocationIdsFilter}
              setImagesSelectedCheckBox={setImagesSelectedCheckBox}
            />
          </div>
          <div className="w-[calc(10%)] min-w-[calc(134px)] z-10">
            <LogBookDropdownHashtags
              infoArray={userTags}
              setFilter={setTagsFilter}
              filter={tagsFilter}
              setImagesSelectedCheckBox={setImagesSelectedCheckBox}
            />
          </div>
          <div className="flex z-10">
            <LogBookStatusFilter
              setStatusFilter={setStatusFilter}
              statusFilter={statusFilter}
              setImagesSelectedCheckBox={setImagesSelectedCheckBox}
            />
          </div>
        </div>
      </div>
      <div>
        <LogBookFilterButtons
          handleChangeSelectAll={handleChangeSelectAll}
          imagesSelectedCheckBox={imagesSelectedCheckBox}
          setShowModalDeleteImage={setShowModalDeleteImage}
          projectNameSelected={projectNameSelected}
          setUserImages={setUserImages}
          user={user}
          userProjects={userProjects}
          setShowModalChangeCategory={setShowModalChangeCategory}
        />
      </div>
    </div>
  );
};
export default LogbookFilters;
