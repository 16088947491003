const LogBookImageTags = ({ imageInfo }) => {
  return (
    <div className="pt-6 log-book-image-info-tags-div mb-5">
      <p className="mb-4 text-xxsmall-bold text-colorBgPrimary cursor-default">Tags</p>
      <div className="flex flex-wrap w-full">
        {imageInfo?.image?.tags?.map((tag, key) => {
          return (
            <span
              key={key}
              className="log-book-image-info-tags-item text-center text-xxsmall text-colorBgSecondary pl-2 pr-2 pt-1.5 pb-1 rounded-2xl mr-2 mt-2 cursor-default"
            >
              #{tag}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default LogBookImageTags;
