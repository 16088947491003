import Modal from "react-modal";
import "../styles/modal-login.css";
const ModalTerms = ({ showModalTerms, setShowModalTerms }) => {
  const closeModal = () => {
    setShowModalTerms();
  };
  const customStyles = {
    content: {
      background: "white",
      color: "#25272d",
      width: "469px",
      height: "465px",
      top: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
      overflowY: "hidden",
    },
    overlay: { zIndex: 1000, background: "rgba(0, 0, 0, 0.6)" },
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModalTerms}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col justify-center items-center h-full">
          <div className="flex w-full justify-between items-center mt-6">
            <p className="text-big">Terms of Service</p>
            <img
              className="cursor-pointer"
              onClick={() => setShowModalTerms()}
              src="/assets/close-modal.svg"
              alt="close"
            />
          </div>
          <div className="modal-login-text h-96 overflow-y-scroll mt-10 overflow-styles">
            <p className="text-xxsmall pr-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
              condimentum eget purus in. Consectetur eget id morbi amet amet,
              in. Ipsum viverra pretium tellus neque.
            </p>
            <p className="text-xxsmall mt-6 pr-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
              condimentum eget purus in. Consectetur eget id morbi amet amet,
              in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
              aenean leo pharetra in sit semper et. Amet quam placerat sem.
            </p>
            <p className="text-xxsmall mt-6 pr-3">
              Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam.
              Pellentesque ornare sem lacinia quam venenatis vestibulum.
              Praesent commodo cursus magna, vel scelerisque nisl consectetur
              et. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec
              sed odio dui. Cras mattis consectetur purus sit amet fermentum.
              Cras justo odio, dapibus ac facilisis in, egestas eget quam. Fusce
              dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh,
              ut fermentum massa justo sit amet risus. Praesent commodo cursus
              magna, vel scelerisque nisl consectetur et. Donec sed odio dui.
            </p>
            <p className="text-xxsmall mt-6 pr-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
              condimentum eget purus in. Consectetur eget id morbi amet amet,
              in.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalTerms;
