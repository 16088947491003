import { useEffect, useState } from "react";
import { isToday, isYesterday, sortArray } from "../lib/utils";

const LogBookImages = ({
  userImages,
  floorsInfo,
  imageId,
  setImageId,
  setImagesSelectedCheckBox,
  imagesSelectedCheckBox,
  setImageInfo,
  windowWidth,
}) => {
  const totalWidth = windowWidth - 32;
  const availableColumns = totalWidth / 263;
  const actualColumns = Math.trunc(availableColumns);
  const spaceTaken = actualColumns * 263;
  const emptySpace = totalWidth - spaceTaken;
  const calculatedMargin = emptySpace / 2;

  const [imagesWithDates, setImagesWithDates] = useState();
  const [imagesDates, setImagesDates] = useState();
  const [reselectImagesResult, setReselectImagesResult] = useState([]);

  useEffect(() => {
    orderImagesByDate();
    setReselectImagesResult(reselectImages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userImages]);

  useEffect(() => {
    if (reselectImagesResult?.length > 0) {
      setImagesSelectedCheckBox(reselectImagesResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reselectImagesResult]);

  const reselectImages = () => {
    let auxImagesSelectedCheckBox = [];
    // eslint-disable-next-line array-callback-return
    userImages?.map((image) => {
      if (imagesSelectedCheckBox?.some((i) => i.id === image.id)) {
        auxImagesSelectedCheckBox.push({
          name: image.title,
          id: image.id,
          url: image.publicUrl,
          projectId: image.projectId,
          title: image.title,
          timestampCreated: image.timestampCreated.seconds,
        });
      }
      if (!imagesSelectedCheckBox?.some((i) => i.id === image.id)) {
        setImagesSelectedCheckBox([]);
      }
    });
    return auxImagesSelectedCheckBox;
  };
  const orderImagesByDate = () => {
    let userImagesWithDates = [];

    userImages?.map((image) => {
      if (isToday(new Date(image.timestampCreated.seconds * 1000))) {
        return userImagesWithDates.push({
          date: "Today",
          image: image,
          timestamp: image.timestampCreated.seconds,
        });
      }
      if (isYesterday(new Date(image.timestampCreated.seconds * 1000))) {
        return userImagesWithDates.push({
          date: "Yesterday",
          image: image,
          timestamp: image.timestampCreated.seconds,
        });
      }
      if (
        !isToday(new Date(image.timestampCreated.seconds * 1000)) &&
        !isYesterday(new Date(image.timestampCreated.seconds * 1000))
      ) {
        let imageDay =
          new Date(image.timestampCreated.seconds * 1000).getDate() < 10
            ? "0" + new Date(image.timestampCreated.seconds * 1000).getDate()
            : new Date(image.timestampCreated.seconds * 1000).getDate();
        let imageMonth =
          new Date(image.timestampCreated.seconds * 1000).getMonth() + 1;
        if (imageMonth < 10) {
          imageMonth = "0" + imageMonth;
        }
        let imageYear = new Date(
          image.timestampCreated.seconds * 1000
        ).getFullYear();
        return userImagesWithDates.push({
          date: imageDay + "/" + imageMonth + "/" + imageYear,
          image: image,
          timestamp: image.timestampCreated.seconds,
        });
      }

      return null;
    });
    let dates = [];
    userImagesWithDates = userImagesWithDates.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    userImagesWithDates?.map((image) => {
      return dates.push(image.date);
    });
    dates = [...new Set(dates)];
    setImagesDates(sortArray(dates));
    setImagesWithDates(userImagesWithDates);
  };

  const addImageCheckBox = (e, image, key) => {
    if (e.target.checked) {
      setImagesSelectedCheckBox([
        ...imagesSelectedCheckBox,
        {
          name: image.image.title,
          url: image.image.publicUrl,
          id: image.image.id,
          projectId: image.image.projectId,
          title: image.image.title,
          timestampCreated: image.image.timestampCreated.seconds,
        },
      ]);
      if (imagesSelectedCheckBox.length === userImages.length - 1) {
        document.getElementById(`log-book-checkbox`).checked = true;
      }
      document.getElementById(`log-book-images-item-${key}`).style.opacity = 1;
    } else {
      setImagesSelectedCheckBox(
        imagesSelectedCheckBox.filter((i) => i.id !== image.image.id)
      );
      document.getElementById(`log-book-checkbox`).checked = false;
    }
  };

  const showImagesInfo = (key) => {
    document.getElementById(`log-book-images-item-${key}`).style.opacity = 1;
  };

  const hideImagesInfo = (key) => {
    if (
      document.getElementById(`log-book-image-${key}-checkbox`).checked ===
      false
    ) {
      document.getElementById(`log-book-images-item-${key}`).style.opacity = 0;
    }
  };

  //falta ordenar array de imagesDates para q se muestren primero today, luego yesterday luego el resto
  return (
    <div
      className="flex flex-col w-full log-book-images-div"
      style={{ marginLeft: calculatedMargin + "px" }}
    >
      {userImages &&
        !imageId &&
        imagesDates?.map((date, key) => {
          return (
            <div key={key} className="flex flex-col">
              <div className="mt-4 flex items-center pt-3 ml-5">
                <img
                  className="log-book-clock-image"
                  src="/assets/clock.svg"
                  alt="clock"
                />
                <span className="text-time text-colorBgPrimary cursor-default">
                  {date}
                </span>
              </div>
              <div className="flex flex-wrap w-full">
                {/* eslint-disable-next-line array-callback-return */}
                {imagesWithDates?.map((image, key) => {
                  if (image.date === date) {

                    return (
                      <div
                        key={key}
                        className="relative log-book-image-item-div-space"
                      >
                        <div
                          className="log-book-images-item-div mb-16 mt-5"
                          onMouseOver={() => showImagesInfo(key)}
                          onMouseLeave={() => hideImagesInfo(key)}
                        >
                          <img
                            onClick={() => {
                              setImageId(image.image.id);
                              setImageInfo(image);
                              // setImagesSelectedCheckBox([]);
                            }}
                            className="log-book-images cursor-pointer"
                            title={
                              image.image.comment
                                ? image.image.comment
                                : "No note"
                            }
                            alt={`img-${key}`}
                            src={
                              image.image.publicTinyUrl
                                ? image.image.publicTinyUrl
                                : image.image.publicUrl
                            }
                          />

                          <div
                            id={`log-book-images-item-${key}`}
                            className="opacity-0"
                            style={{
                              opacity:
                                imagesSelectedCheckBox?.some(
                                  (i) => i.id === image.image.id
                                ) && "1",
                            }}
                          >
                            <div className="absolute top-0 pt-8 pl-3.5">
                              <input
                                id={`log-book-image-${key}-checkbox`}
                                checked={
                                  imagesSelectedCheckBox?.some(
                                    (i) => i.id === image.image.id
                                  ) && true
                                }
                                className="cursor-pointer log-book-checkbox"
                                type="checkbox"
                                onChange={(e) =>
                                  addImageCheckBox(e, image, key)
                                }
                              />
                            </div>
                            {image?.image?.comment &&
                              image?.image?.comment !== "" && (
                                <div
                                  className="absolute top-0 pt-8 right-3.5 cursor-pointer"
                                  onClick={() => {
                                    setImageId(image.image.id);
                                    setImageInfo(image);
                                    // setImagesSelectedCheckBox([]);
                                  }}
                                >
                                  <img
                                    src="/assets/images-comments.svg"
                                    alt="comments"
                                  />
                                </div>
                              )}

                            {image?.image?.tags?.length > 0 && (
                              <div
                                className="absolute bottom-20 right-3.5 cursor-pointer"
                                onClick={() => {
                                  setImageId(image.image.id);
                                  setImageInfo(image);
                                  // setImagesSelectedCheckBox([]);
                                }}
                              >
                                {image?.image?.tags?.length > 2 && (
                                  <div className="log-book-images-tags-div flex items-center justify-center rounded-xl w-9 h-7">
                                    <p className="pt-0.5 text-time text-[#FAFAFA]">
                                      {image?.image?.tags?.length}x
                                    </p>
                                  </div>
                                )}
                                {image?.image?.tags?.length <= 2 &&
                                  image.image.tags.map((tag, key) => {
                                    return (
                                      <div
                                        key={key}
                                        className="log-book-images-tags-div flex items-center justify-center rounded-lg px-2 h-7 mt-2"
                                      >
                                        <p className="pt-0.5 text-time text-[#FAFAFA]">
                                          #{tag}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                          <div className="flex log-book-images-info-div pl-1">
                            {image.image.status === 1 && (
                              <img
                                className="log-book-status-image mr-2.5"
                                src="/assets/rectangle-color.svg"
                                alt="rectangle"
                              />
                            )}
                            {image.image.status === 2 && (
                              <img
                                className="log-book-status-image mr-2.5"
                                src="/assets/ellipse-color.svg"
                                alt="ellipse"
                              />
                            )}
                            {image.image.status === 3 && (
                              <img
                                className="log-book-status-image-polygon mr-2.5"
                                src="/assets/polygon-color.svg"
                                alt="polygon"
                              />
                            )}
                            <div className="flex flex-col">
                              <span className="text-xxsmall text-colorBgPrimary cursor-default">
                                {image.image.title}
                              </span>

                              <span className="text-xxsmall text-[#8A8A8A] mt-1.5 cursor-default">
                                {
                                  floorsInfo.filter(
                                    (floor) =>
                                      floor.id === image?.image?.floorLocationId
                                  )[0]?.name
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default LogBookImages;
