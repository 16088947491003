import Modal from "react-modal";
import { updateUserStateModalDB } from "../lib/db";
import "../styles/modal-welcome.css";
const ModalWelcome = ({ showModalWelcome, setShowModalWelcome, user }) => {
  const closeModal = () => {
    setShowModalWelcome();
  };
  const customStyles = {
    content: {
      background: "white",
      color: "#25272d",
      width: "469px",
      height: "517px",
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-48px",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
    },
    overlay: { zIndex: 1000, background: "rgba(0, 0, 0, 0.6)" },
  };

  const updateStateModal = async () => {
    if (document.getElementById("modal-welcome-checkbox").checked) {
      await updateUserStateModalDB(user.id);
    }
    setShowModalWelcome();
  };

  const handleClickText = () => {
    if (document.getElementById("modal-welcome-checkbox").checked) {
      document.getElementById("modal-welcome-checkbox").checked = false;
    } else {
      document.getElementById("modal-welcome-checkbox").checked = true;
    }
  };
  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModalWelcome}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col justify-center items-center">
          <p className="text-big mt-12 mb-2.5">Welcome to Logbook</p>
          <p className="text-xxsmall-bold text-center w-60">
            View all your captured photos in one place.
          </p>
          <img className="mt-3 mb-5" src="/assets/modal-img.svg" alt="welcome" />
          <div>
            <span className="modal-welcome-border text-colorBgPrimary text-web-xsmall pr-5 mr-5">
              Sort
            </span>
            <span className="modal-welcome-border text-colorBgPrimary text-web-xsmall pr-5 mr-5">
              Filter
            </span>
            <span className="modal-welcome-border text-colorBgPrimary text-web-xsmall pr-5 mr-5">
              Review
            </span>
            <span className="text-colorBgPrimary text-web-xsmall">Save</span>
          </div>

          <div className="flex items-center justify-between w-full mt-14">
            <div className="flex items-center">
              <input
                className="w-4 h-5 cursor-pointer"
                type="checkbox"
                id="modal-welcome-checkbox"
              />
              <span
                className="text-web-xsmall-bold ml-2 mt-1 cursor-pointer"
                onClick={handleClickText}
              >
                Don't show again
              </span>
            </div>
            <button className="button-blue text-web-button" onClick={updateStateModal}>
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalWelcome;
