import { useEffect, useRef, useState } from "react";
import "../styles/dropdowns.css";
const LogBookDropdowImagesCategories = ({ setShowModalChangeCategory }) => {
  const [isList, setIsList] = useState(false);

  //start to close list when click outside the list
  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsList();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  //end to close list when click outside the list

  const changeStyleItemTagDiv = (action, key) => {
    if (action === "hover") {
      document.getElementById(`item-categories-div-${key}`).style.background =
        "#F1F3F6";
    } else if (action === "leave") {
      document.getElementById(`item-categories-div-${key}`).style.background =
        "white";
    }
  };

  return (
    <div className="z-10" ref={wrapperRef}>
      <div className="w-44 flex justify-end relative">
        <div
          onClick={() => setIsList(!isList)}
          style={{
            border: isList ? "1px solid #F1F3F6" : "1px solid transparent",
          }}
          className="z-10 w-fit  rounded bg-white text-xxsmall-bold  flex items-center cursor-pointer pl-2 pt-2 pb-1"
        >
          <div className="w-fit flex">
            <span className="pt-0.5 text-colorBgPrimary">Category Selection</span>
          </div>
          <div className="w-fit">
            {isList ? (
              <div className="z-10  pl-2 pr-2">
                <img src="/assets/up.svg" alt="close" />
              </div>
            ) : (
              <div className="z-10 pl-2 pr-2">
                <img src="/assets/down.svg" alt="open" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isList && (
        <div className="z-10 w-44 mt-3 py-3 px-2 shadow rounded overflow-y-scroll dropdown-list overflow-styles absolute">
          <div className="z-10 flex items-center  justify-between">
            <div className="z-10 flex items-center w-full">
              <div className="z-10 flex flex-col w-full cursor-pointer">
                <div
                  id={`item-categories-div-1`}
                  className="w-full flex dropdown-projects-item pt-4 pb-3"
                  onMouseOver={() => changeStyleItemTagDiv("hover", 1)}
                  onMouseLeave={() => changeStyleItemTagDiv("leave", 1)}
                  onClick={() => {
                    setShowModalChangeCategory(1)
                    setIsList();
                  }}
                >
                  <img
                    src="/assets/rectangle-color.svg"
                    alt="category"
                    className="pl-2.5 pb-1 pr-2.5"
                  />
                  <span className="text-xxsmall pt-0.5 text-colorBgPrimary">
                    Check
                  </span>
                </div>
                <div
                  id={`item-categories-div-2`}
                  className="w-full flex dropdown-projects-item pt-4 pb-3"
                  onMouseOver={() => changeStyleItemTagDiv("hover", 2)}
                  onMouseLeave={() => changeStyleItemTagDiv("leave", 2)}
                  onClick={() => {
                    setShowModalChangeCategory(2)
                    setIsList();
                  }}
                >
                  <img
                    src="/assets/ellipse-color.svg"
                    alt="category"
                    className="pl-2.5 pb-1 pr-2.5"
                  />
                  <span className="text-xxsmall pt-0.5 text-colorBgPrimary">
                    Progress
                  </span>
                </div>
                <div
                  id={`item-categories-div-3`}
                  className="flex pt-4 pb-3"
                  onMouseOver={() => changeStyleItemTagDiv("hover", 3)}
                  onMouseLeave={() => changeStyleItemTagDiv("leave", 3)}
                  onClick={() => {
                    setShowModalChangeCategory(3)
                    setIsList();
                  }}
                >
                  <img
                    src="/assets/polygon-color.svg"
                    alt="category"
                    className="pl-2.5 pb-1 pr-2.5"
                  />
                  <span className="text-xxsmall pt-0.5 text-colorBgPrimary">
                    Issue
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogBookDropdowImagesCategories;
