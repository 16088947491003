import { useEffect, useRef, useState } from "react";
import "../styles/dropdowns.css";
const LogBookDropdownHashtags = ({
  infoArray,
  setFilter,
  filter,
  // setImagesSelectedCheckBox,
}) => {
  const [isList, setIsList] = useState(false);
  const [hashtagName, setHashtagName] = useState("Hashtags");
  const [infoArrayFiltered, setInfoArrayFiltered] = useState([]);
  const [inputText, setInputText] = useState("");

  //start to close list when click outside the list
  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsList(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  //end to close list when click outside the list

  useEffect(() => {
    if (filter?.length <= 0) {
      setHashtagName("Hashtags");
    } else if (filter?.length === 1) {
      setHashtagName(filter[0]);
    } else {
      setHashtagName(
        filter.length + (filter.length > 1 ? "x Hashtags" : "x Hashtag")
      );
    }
  }, [filter]);

  const handleClickTag = (value) => {
    filter.indexOf(value) === -1 && setFilter([...filter, value]);
    document.getElementById(`log-book-checkbox`).checked = false;
    // setImagesSelectedCheckBox([]);
  };

  useEffect(() => {
    if (inputText !== "") {
      setInfoArrayFiltered(
        infoArray?.filter((tag) => tag.toLowerCase().startsWith(inputText.toLowerCase()))
      );
    } else {
      setInfoArrayFiltered(infoArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText]);

  const changeStyleItemTagDiv = (action, key, item) => {
    if (action === "hover") {
      document.getElementById(
        `dropdown-hashtag-item-div-${key}`
      ).style.background = "#F1F3F6";
      if (!filter?.includes(item)) {
        document.getElementById(
          `dropdown-hashtag-item-${key}`
        ).style.background = "#32323C";
        document.getElementById(`dropdown-hashtag-item-img-${key}`).src =
          "/assets/hashtag-icon-hover.svg";
      }
    } else if (action === "leave") {
      document.getElementById(
        `dropdown-hashtag-item-div-${key}`
      ).style.background = "white";
      if (!filter?.includes(item)) {
        document.getElementById(
          `dropdown-hashtag-item-${key}`
        ).style.background = "#F1F3F6";
        document.getElementById(`dropdown-hashtag-item-img-${key}`).src =
          "/assets/hashtag-icon.svg";
      }
    }
  };
  return (
    <div className="z-10" ref={wrapperRef}>
      <div className="w-60">
        <div
          onClick={() => setIsList(!isList)}
          className="z-10 w-fit pt-3 pb-1 rounded bg-white text-xxsmall-bold  flex items-center cursor-pointer"
          style={{
            border: isList ? "1px solid #F1F3F6" : "1px solid transparent",
          }}
        >
          <span className="pl-1 text-colorBgPrimary">{hashtagName}</span>
          <div>
            {isList ? (
              <div className="z-10 pl-3 pr-2 pb-1">
                <img src="/assets/up.svg" alt="close" />
              </div>
            ) : (
              <div className="z-10 pl-3 pr-2">
                <img src="/assets/down.svg" alt="open" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isList && (
        <div className="w-60 z-10 bg-[white] shadow rounded dropdown-hashtag mt-2">
          <div className="z-10 flex  items-center">
            <div className="mt-2  z-10 flex items-center ">
              <div className="z-10 flex items-center">
                <div
                  className="z-10 flex justify-center items-center ml-2 relative bg-[#F1F3F6] rounded-xl"
                  style={{
                    background: inputText && inputText !== "" && "#FAFAFA",
                    border:
                      inputText && inputText !== "" && "1px solid #F1F3F6",
                  }}
                >
                  <div className="flex items-center">
                    <img
                      className="pl-2"
                      onClick={() => {
                        setInputText("");
                        document.getElementById("input-tag-text").value = "";
                      }}
                      src="/assets/magnifying.svg"
                      alt="magnifying"
                    />
                    <input
                      id="input-tag-text"
                      value={inputText}
                      type="text"
                      className="text-xsmall w-44 log-book-input-tag p-2 h-10 rounded-l-xl bg-[#F1F3F6]"
                      style={{
                        background: inputText && inputText !== "" && "#FAFAFA",
                      }}
                      placeholder="Search by tag..."
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setInputText("");
                        } else {
                          setInputText(e.target.value);
                        }
                      }}
                    ></input>
                  </div>
                  {inputText && inputText !== "" && (
                    <div>
                      <img
                        className="cursor-pointer mr-2 mt-0.5"
                        onClick={() => {
                          setInputText("");
                          document.getElementById("input-tag-text").value = "";
                        }}
                        src="/assets/remove-tag-icon.svg"
                        alt="remove"
                      />
                    </div>
                  )}
                  {(!inputText || inputText === "") && (
                    <div className="cursor-pointer w-4 h-4 mr-2"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="z-10 mt-2 px-2 dropdown-hashtags overflow-y-scroll overflow-styles">
            {infoArrayFiltered?.map((item, key) => {
              return (
                <div
                  key={key}
                  className="z-10 flex items-center justify-between dropdown-projects-item"
                  style={{
                    border: infoArrayFiltered.length === key + 1 && "0",
                    marginBottom: infoArrayFiltered.length === key + 1 && 0,
                  }}
                >
                  <div className="z-10 flex items-center w-full">
                    <div
                      id={`dropdown-hashtag-item-div-${key}`}
                      className="pl-4 z-10 py-3 flex items-center w-full h-full cursor-pointer"
                      onClick={() => handleClickTag(item)}
                      onMouseOver={() =>
                        changeStyleItemTagDiv("hover", key, item)
                      }
                      onMouseLeave={() =>
                        changeStyleItemTagDiv("leave", key, item)
                      }
                    >
                      <div
                        id={`dropdown-hashtag-item-${key}`}
                        className="flex w-7 h-6 items-center justify-center rounded-2xl"
                        style={{
                          background: filter?.includes(item)
                            ? "#32323C"
                            : "#F1F3F6",
                        }}
                      >
                        <img
                          id={`dropdown-hashtag-item-img-${key}`}
                          src={
                            filter?.includes(item)
                              ? "/assets/hashtag-icon-hover.svg"
                              : "/assets/hashtag-icon.svg"
                          }
                          alt="tag"
                        />
                      </div>
                      <p className="w-full z-10 text-xxsmall ml-1 mt-1 text-colorBgPrimary">
                        {item}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* div to show options selected */}
          <div className="flex-wrap flex p-2 text-web-xsmall z-10 dropdown-hashtags-selected-div shadow rounded-b w-60">
            {filter?.length > 0 ? (
              filter.map((item, key) => {
                return (
                  <div
                    className="flex items-center justify-center mr-2 mt-2 p-1 border border-[#F1F3F6] rounded-xl"
                    key={key}
                  >
                    <p className="m-1 mt-1.5">#{item}</p>
                    <img
                      className="cursor-pointer m-1 ml-2"
                      onClick={() => {
                        setFilter(filter.filter((tag) => tag !== item));
                      }}
                      src="/assets/remove-tag-icon.svg"
                      alt="remove"
                    />
                  </div>
                );
              })
            ) : (
              <div className="m-2 w-60 text-center text-colorBgPrimary">
                <p>No tags selected</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LogBookDropdownHashtags;
