export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (yesterday.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
};

export const sortArray = (array) => {
  let newArray = [];
  if (array.includes("Today")) {
    newArray.push("Today");
  }
  if (array.includes("Yesterday")) {
    newArray.push("Yesterday");
  }
  array = array.filter((e) => e !== "Today");
  array = array.filter((e) => e !== "Yesterday");

  let arraySorted = array.sort(function (a, b) {
    var aa = a.split("/").reverse().join(),
      bb = b.split("/").reverse().join();
    return aa > bb ? -1 : aa < bb ? 1 : 0;
  });
  if (arraySorted?.length > 0) {
    arraySorted.map((e) => {
      return newArray.push(e);
    });
  }

  return newArray;
};

export const getTime = (timestamp) => {
  const date =
    timestamp.getDate() < 10 ? "0" + timestamp.getDate() : timestamp.getDate();
  const month =
    timestamp.getMonth() + 1 < 10
      ? "0" + (timestamp.getMonth() + 1)
      : timestamp.getMonth() + 1;
  const hours =
    timestamp.getHours() < 10
      ? "0" + timestamp.getHours()
      : timestamp.getHours();
  const minutes =
    timestamp.getMinutes() < 10
      ? "0" + timestamp.getMinutes()
      : timestamp.getMinutes();
  const seconds =
    timestamp.getSeconds() < 10
      ? "0" + timestamp.getSeconds()
      : timestamp.getSeconds();
  const time =
    date +
    "." +
    month +
    "." +
    timestamp.getFullYear() +
    "-" +
    hours +
    "." +
    minutes +
    "." +
    seconds;
  return time;
};
