import React from "react";
import "../styles/spinner-black.css";

const LoadingSpinnerBlack = () => {
  return (
    <div className="spinner-container-black">
      <div className="loader-black"></div>
    </div>
  );
};

export default LoadingSpinnerBlack;
