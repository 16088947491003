import { useState } from "react";
import { updateUserPasswordDB } from "../lib/db";
import "../styles/profile.css";
import LoadingSpinner from "./loading-spinner";
const LogBookUserProfileSecurityChangePassword = ({
  setShowChangePassword,
  showChangePassword,
  userInfo,
  setShowModalChangesOk,
  setPassword,
}) => {
  const [userEmail] = useState(userInfo.email);
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordConfirm, setUserPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [inputType, setInputType] = useState("password");
  const [inputTypeConfirm, setInputTypeConfirm] = useState("password");
  const updateUserPassword = async () => {
    setIsLoading(true);
    const response = await updateUserPasswordDB(userPassword);
    if (response) {
      setUserPassword("");
      setUserPasswordConfirm("");
      setPassword(userPassword);
      document.getElementById("user-profile-password-input").value = "";
      document.getElementById("user-profile-password-confirm-input").value = "";
      setShowModalChangesOk("Password");
      setTimeout(() => {
        setShowModalChangesOk();
      }, 2000);
    }
    setIsLoading();
  };

  return (
    <div className="w-full h-[calc(100%)]">
      <div className="profile-title-div flex  flex-col pt-9 pb-10">
        <div className="flex items-center">
          <img
            onClick={() => {
              setShowChangePassword(!showChangePassword);
            }}
            src="/assets/left.svg"
            alt="back"
            className="pl-11 pb-1 pr-14 mb-2.5 cursor-pointer"
          />
          <p className="text-h5 w-full profile-title-name text-colorBgPrimary cursor-default">Change Password</p>
        </div>
        <div className="flex w-full justify-center items-center">
          <p className="text-web-xsmall w-full text-center w-2/3 text-colorBgPrimary cursor-default">
            Set a new password for your Logbook account or simply keep the old
            one.
          </p>
        </div>
      </div>
      <div className="px-9 flex pt-8 h-[calc(100%-137px)]">
        <div className="flex flex-col w-full ">
          <p className="text-web-xsmall-bold mb-2.5 text-colorBgPrimary cursor-default">Email</p>
          <div className="flex items-center bg-[white] w-full rounded-lg mb-8 opacity-50">
            <input
              className="text-web-xsmall py-2 pl-4 h-11 w-10/12 bg-[white] rounded-lg outline-0"
              defaultValue={userEmail}
              disabled
              type="text"
            />
            <div className="flex h-11 items-center w-2/12 pr-4 justify-end">
              <img src="/assets/locked.svg" alt="edit" />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <p className="text-web-xsmall-bold mb-2.5 text-colorBgPrimary cursor-default">New password</p>
            <div className="flex items-center bg-[white] w-full rounded-lg mb-8">
              <input
                id="user-profile-password-input"
                autoComplete="off"
                onChange={(e) => setUserPassword(e.target.value)}
                className="text-web-xsmall py-2 pl-4 h-11 w-10/12 bg-[white] rounded-lg outline-0"
                value={userPassword}
                type={inputType}
              />
              <div className="flex h-11 items-center w-2/12 pr-4 justify-end">
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    inputType === "text"
                      ? setInputType("password")
                      : setInputType("text");
                  }}
                  src={
                    inputType === "password"
                      ? "/assets/eye.svg"
                      : "/assets/eye-hide.svg"
                  }
                  alt="password"
                />
              </div>
            </div>
          </div>
          <div
            id="user-profile-email-confirm-div"
            className="flex flex-col w-full"
          >
            <p className="text-web-xsmall-bold mb-2.5 text-colorBgPrimary cursor-default">New password</p>
            <div className="flex items-center bg-[white] w-full rounded-lg mb-4">
              <input
                id="user-profile-password-confirm-input"
                autoComplete="off"
                onChange={(e) => setUserPasswordConfirm(e.target.value)}
                className="text-web-xsmall py-2 pl-4 h-11 w-10/12 bg-[white] rounded-lg outline-0"
                value={userPasswordConfirm}
                type={inputTypeConfirm}
              />
              <div className="flex h-11 items-center w-2/12 pr-4 justify-end">
                <img
                  className="cursor-pointer"
                  onClick={() => {
                    inputTypeConfirm === "text"
                      ? setInputTypeConfirm("password")
                      : setInputTypeConfirm("text");
                  }}
                  src={
                    inputTypeConfirm === "password"
                      ? "/assets/eye.svg"
                      : "/assets/eye-hide.svg"
                  }
                  alt="password"
                />
              </div>
            </div>
          </div>
          <div>
            <p className="text-xxsmall text-colorBgPrimary cursor-default">
              For security reasons, please use <b>8 or more characters</b> with
              a mix of <b>letters, numbers & symbols.</b>
            </p>
          </div>

          <div className="px-9 h-[calc(100%)] justify-center flex items-end pt-8 pb-20">
            {(!userPassword ||
              !userPasswordConfirm ||
              userPassword !== userPasswordConfirm ||
              userPassword.length < 8) &&
              !isLoading && (
                <button className="button-black w-40 text-web-button cursor-default">
                  Confirm
                </button>
              )}
            {userPassword &&
              userPasswordConfirm &&
              userPassword === userPasswordConfirm &&
              userPassword.length > 7 &&
              !isLoading && (
                <button
                  onClick={updateUserPassword}
                  className="button-blue w-40 text-web-button"
                  type="button"
                >
                  Confirm
                </button>
              )}
            {isLoading && (
              <button className="button-blue w-40 text-web-button cursor-default">
                <LoadingSpinner />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogBookUserProfileSecurityChangePassword;
