import Modal from "react-modal";
import { changeStatusDB, getUserImagesDB } from "../lib/db";
const ModalChangeCategory = ({
  showModalChangeCategory,
  setShowModalChangeCategory,
  imagesSelectedCheckBox,
  setUserImages,
  user,
  setNewStateImage,
  projectIdFilter,
  floorLocationIdsFilter,
  statusFilter,
  filterImages,
}) => {
  const changeStatus = async () => {
    if (!showModalChangeCategory.newStatus) {
      await changeStatusDB(
        imagesSelectedCheckBox,
        showModalChangeCategory,
        user.id
      );
    } else {
      await changeStatusDB(
        showModalChangeCategory.image,
        showModalChangeCategory.newStatus,
        user.id
      );
      setNewStateImage(showModalChangeCategory.newStatus);
    }
    setUserImages(await getUserImagesDB(user.id));
    if (projectIdFilter || floorLocationIdsFilter || statusFilter) {
      filterImages();
    }
  };

  const closeModal = () => {
    setShowModalChangeCategory();
  };
  const customStyles = {
    content: {
      background: "white",
      color: "#25272d",
      width: "300px",
      height: "195px",
      top: "35%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "6px",
    },
    overlay: { zIndex: 1000, background: "rgba(0, 0, 0, 0.6)" },
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModalChangeCategory && true}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col">
          <div className="flex flex-col text-center">
            <p className="pt-5 text-h5 text-colorBgPrimary">Change Category</p>
            <p className="pt-2 text-xsmall text-colorBgPrimary">
              Are you sure you want to change the category?
            </p>
            <div className="pt-6 flex justify-around">
              <button
                className="button-cancel text-xsmall-bold mr-3"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="button-blue pb-1 text-xsmall-bold"
                type="button"
                onClick={() => {
                  changeStatus();
                  closeModal();
                }}
              >
                Change
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalChangeCategory;
