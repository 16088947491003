import "../styles/login-footer.css";

const LoginFooter = ({
  showModalPrivacyPolicy,
  setShowModalPrivacyPolicy,
  showModalTerms,
  setShowModalTerms,
}) => {
  return (
    <div className="flex absolute bottom-0 bg-transparent footer-div items-center w-full justify-between pr-10 pl-9">
      <div className="flex items-center h-full">
        <span className="text-footer text-colorTextWhite cursor-default">mylogbookapp.com</span>
        {/* <span className="pl-1 pt-0.5 text-xsmall-privacy text-colorBgPrimary cursor-default">
          Build: 9
        </span> */}
      </div>
      <div className="flex items-center h-full">
        <p
          className="mr-6 text-footer cursor-pointer text-colorTextWhite"
          onClick={() => {
            setShowModalPrivacyPolicy(!showModalPrivacyPolicy);
          }}
        >
          Privacy
        </p>
      </div>
    </div>
  );
};
export default LoginFooter;
