import { useEffect, useRef, useState } from "react";
import "../styles/dropdowns.css";
const LogBookDropdowImageCategories = ({
  setUserImages,
  imageInfo,
  user,
  setShowModalChangeCategory,
  newStateImage,
}) => {
  const [isList, setIsList] = useState(false);
  const [categoryName, setCategoryName] = useState();
  const [imageCategoryUrl, setImageCategoryUrl] = useState();
  const [imageStatus, setImageStatus] = useState(imageInfo.image.status);

  useEffect(() => {
    if (newStateImage) {
      changeStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newStateImage]);

  useEffect(() => {
    if (imageStatus === 1) {
      setCategoryName("Check");
      setImageCategoryUrl("/assets/rectangle-color.svg");
    }
    if (imageStatus === 2) {
      setCategoryName("Progress");
      setImageCategoryUrl("/assets/ellipse-color.svg");
    }
    if (imageStatus === 3) {
      setCategoryName("Issue");
      setImageCategoryUrl("/assets/polygon-color.svg");
    }
  }, [imageStatus, newStateImage]);

  const changeStatus = async () => {
    // await changeStatusDB([{ id: imageInfo.image.id }], newStateImage, user.id);
    setImageStatus(newStateImage);
    // setUserImages(await getUserImagesDB(user.id));
  };

  //start to close list when click outside the list
  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsList();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  //end to close list when click outside the list

  return (
    <div className="z-10" ref={wrapperRef}>
      <div className="w-40 flex justify-end relative">
        <div
          onClick={() => setIsList(!isList)}
          className="z-10 w-fit  rounded bg-white  flex items-center cursor-pointer"
        >
          <div className="w-fit flex">
            <img
              src={imageCategoryUrl}
              alt="category"
              className="pb-1 pr-2.5"
            />
            <span className="pt-0.5 text-xxsmall text-colorBgPrimary">
              {categoryName}
            </span>
          </div>
          <div className="w-fit">
            {isList ? (
              <div className="z-10  pl-2">
                <img src="/assets/up.svg" alt="close" />
              </div>
            ) : (
              <div className="z-10 pl-2">
                <img src="/assets/down.svg" alt="open" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isList && (
        <div className="z-10 w-52 mt-3 pt-3 px-2 shadow rounded overflow-y-scroll dropdown-list overflow-styles absolute right-8 ">
          <div className="z-10 flex items-center  justify-between mb-2">
            <div className="z-10 flex items-center w-full">
              <div className="z-10 flex flex-col w-full cursor-pointer">
                <div
                  className="w-full flex dropdown-projects-item pt-3 pb-2 pl-2"
                  style={{
                    background: categoryName === "Check" && "#F1F3F6",
                    border:
                      (categoryName === "Check" ||
                        categoryName === "Progress") &&
                      "0",
                  }}
                  onClick={() => {
                    setShowModalChangeCategory({
                      newStatus: 1,
                      image: [{ id: imageInfo.image.id }],
                    });
                    setIsList();
                  }}
                >
                  <img
                    src="/assets/rectangle-color.svg"
                    alt="category"
                    className="pb-1 pr-2.5"
                  />
                  <span
                    className="text-xxsmall pt-0.5 text-colorBgPrimary"
                    style={{ fontWeight: categoryName === "Check" && "bold" }}
                  >
                    Check
                  </span>
                </div>
                <div
                  style={{
                    background: categoryName === "Progress" && "#F1F3F6",
                    border:
                      (categoryName === "Progress" ||
                        categoryName === "Issue") &&
                      "0",
                  }}
                  className="w-full flex dropdown-projects-item pt-3 pb-2 pl-2"
                  onClick={() => {
                    setShowModalChangeCategory({
                      newStatus: 2,
                      image: [{ id: imageInfo.image.id }],
                    });
                    setIsList();
                  }}
                >
                  <img
                    src="/assets/ellipse-color.svg"
                    alt="category"
                    className="pb-1 pr-2.5"
                  />
                  <span
                    className="text-xxsmall pt-0.5 text-colorBgPrimary"
                    style={{
                      fontWeight: categoryName === "Progress" && "bold",
                    }}
                  >
                    Progress
                  </span>
                </div>
                <div
                  style={{
                    background: categoryName === "Issue" && "#F1F3F6",
                    border: categoryName === "Issue" && "0",
                  }}
                  className="flex pt-3 pb-2 pl-2"
                  onClick={() => {
                    setShowModalChangeCategory({
                      newStatus: 3,
                      image: [{ id: imageInfo.image.id }],
                    });
                    setIsList();
                  }}
                >
                  <img
                    src="/assets/polygon-color.svg"
                    alt="category"
                    className="pb-1 pr-2.5"
                  />
                  <span
                    className="text-xxsmall pt-0.5 text-colorBgPrimary"
                    style={{ fontWeight: categoryName === "Issue" && "bold" }}
                  >
                    Issue
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogBookDropdowImageCategories;
