import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

const LogBookImageButtons = ({
  imageInfo,
  setShowModalDeleteImage,
  userProjects,
}) => {
  const [projectName, setProjectName] = useState();

  useEffect(() => {
    const filterProject = userProjects?.filter(
      (project) => project.id === imageInfo?.image?.projectId
    );
    if (filterProject) {
      setProjectName(filterProject[0]?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProjects]);

  const downloadImg = async (img) => {
    const image = await fetch(img[0]?.url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const imageTitle = img[0]?.title;
    const link = document.createElement("a");
    const photoName = imageTitle + ".png";
    link.href = imageURL;
    link.download = photoName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="flex items-end justify-center pb-5 log-book-image-info-buttons-div w-full">
      <div className="flex items-end w-full justify-center">
        <div className="flex w-full justify-end log-book-image-info-text-div items-center py-4">
          <img
            onClick={() => setShowModalDeleteImage(true)}
            className="log-book-image-info-buttons-trash-image cursor-pointer"
            src="/assets/trash.svg"
            alt="delete"
          />
          <button
            onClick={() => {
              mixpanel.track("Download images", {
                "Number of images": 1,
              });
              downloadImg([
                {
                  name: imageInfo?.image?.title,
                  url: imageInfo?.image?.publicUrl,
                  title: imageInfo?.image?.title,
                  timestampCreated: imageInfo?.timestamp,
                  projectName: projectName,
                },
              ]);
            }}
            className="button-blue text-web-button"
            type="button"
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogBookImageButtons;
