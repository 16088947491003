import { saveAs } from "file-saver";
import JSZip from "jszip";
import { getTime } from "../lib/utils";
import LogBookDropdowImagesCategories from "./log-book-dropdown-images-categories";
import mixpanel from "mixpanel-browser";

const LogBookFilterButtons = ({
  handleChangeSelectAll,
  imagesSelectedCheckBox,
  setShowModalDeleteImage,
  projectNameSelected,
  setUserImages,
  setShowModalChangeCategory,
  user,
}) => {
  const generateZipFile = (photosToDownload) => {
    var jszip = new JSZip();
    for (let i = 0; i < photosToDownload.length; i++) {
      // let time = getTime(new Date(photosToDownload[i]?.timestampCreated*1000));
      let photoName = photosToDownload[i].title;
      let photoImageURL = photosToDownload[i].url;
      let photoImageExtension = ".png";
      jszip.file(
        photoName + photoImageExtension,
        downloadUrlAsPromise(photoImageURL)
      );

      if (i === photosToDownload.length - 1) {
        jszip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(
            content,
            `${projectNameSelected.name}-${getTime(new Date())}.zip`
          );
        });
      }
    }
  };

  const downloadUrlAsPromise = (url) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer";
      xhr.onreadystatechange = function (evt) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error("Error for " + url + ": " + xhr.status));
          }
        }
      };
      xhr.send();
    });
  };

  const downloadImg = async () => {
    // const time = getTime(new Date(imagesSelectedCheckBox[0]?.timestampCreated*1000));
    const image = await fetch(imagesSelectedCheckBox[0]?.url);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    // const filterProject = userProjects?.filter(
    //   (project) => project.id === imagesSelectedCheckBox[0]?.projectId
    // );
    // const projectName = filterProject[0]?.name;
    const imageTitle = imagesSelectedCheckBox[0]?.title;
    const photoName = imageTitle + ".png";
    link.href = imageURL;
    link.download = photoName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      id="log-book-checkbox-div"
      className="flex items-center w-full log-book-checkbox-div flex-wrap"
    >
      <div className="w-2/12 flex items-center">
        <input
          onChange={(e) => handleChangeSelectAll(e)}
          id="log-book-checkbox"
          className="log-book-checkbox cursor-pointer"
          type="checkbox"
          value="Select all"
        />

        <span className="text-xxsmall text-colorBgPrimary ml-2 pt-1 cursor-default">
          Select all
        </span>
      </div>
      <div className="z-0 logbook-filter-buttons-rigth-div flex items-center w-10/12 justify-end flex-wrap">
        <div
          style={{
            opacity: imagesSelectedCheckBox?.length < 1 && "0.4",
            cursor: imagesSelectedCheckBox?.length < 1 && "default",
            pointerEvents: imagesSelectedCheckBox?.length < 1 && "none",
          }}
        >
          <LogBookDropdowImagesCategories
            imagesSelectedCheckBox={imagesSelectedCheckBox}
            setUserImages={setUserImages}
            user={user}
            setShowModalChangeCategory={setShowModalChangeCategory}
          />
        </div>
        <button
          style={{
            opacity: imagesSelectedCheckBox?.length < 1 && "0.5",
            cursor: imagesSelectedCheckBox?.length < 1 && "default",
            background:
              imagesSelectedCheckBox?.length < 1 && "rgba(209, 85, 49, 1)",
          }}
          onClick={() => {
            if (imagesSelectedCheckBox.length > 0) {
              setShowModalDeleteImage(true);
            }
          }}
          className="button-error log-book-filters-button-delete-images mx-4 flex items-center justify-center logbook-filter-buttons-rigth-div-item"
          type="button"
        >
          <img src="/assets/trash-white.svg" alt="delete" />
        </button>

        {imagesSelectedCheckBox.length > 0 && (
          <button
            onClick={() => {
              if (imagesSelectedCheckBox.length > 1) {
                generateZipFile(imagesSelectedCheckBox);
                mixpanel.track("Download images", {
                  "Number of images": imagesSelectedCheckBox.length,
                });
              } else if (imagesSelectedCheckBox.length === 1) {
                downloadImg(imagesSelectedCheckBox);
                mixpanel.track("Download images", {
                  "Number of images": 1,
                });
              }
            }}
            className="button-download-black text-web-button logbook-filter-buttons-rigth-div-item"
            type="button"
          >
            Download
          </button>
        )}
        {!imagesSelectedCheckBox.length > 0 && (
          <button
            className="button-download-black-disabled text-web-button cursor-default logbook-filter-buttons-rigth-div-item"
            type="button"
            style={{
              opacity: imagesSelectedCheckBox?.length < 1 && "0.5",
              cursor: imagesSelectedCheckBox?.length < 1 && "default",
            }}
          >
            Download
          </button>
        )}
      </div>
    </div>
  );
};

export default LogBookFilterButtons;
