import Modal from "react-modal";
const ModalChangesOK = ({ showModalChangesOk, setShowModalChangesOk }) => {
  const closeModal = () => {
    setShowModalChangesOk();
  };
  const customStyles = {
    content: {
      background: "white",
      color: "#25272d",
      width: "230px",
      height: "195px",
      top: "50%",
      right: "0%",
      left: "auto",
      bottom: "auto",
      marginRight: "-48px",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
    },
    overlay: { zIndex: 1000, background: "rgba(0, 0, 0, 0.6)" },
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModalChangesOk ? true : null}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col justify-center items-center">
          <img className="mt-3 mb-5" src="/assets/check.svg" alt="done" />
          <p className="text-center">{showModalChangesOk} changed successfully!</p>
        </div>
      </Modal>
    </div>
  );
};

export default ModalChangesOK;
