import "../styles/login.css";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logUserDB } from "../lib/db";
import LoginFooter from "./login-footer";
import ModalPrivacyPolicy from "./modalPrivacyPolicy";
import ModalTerms from "./modalTerms";
import mixpanel from "mixpanel-browser";
import LoadingSpinnerBlack from "./loading-spinner-black";

const Login = () => {
  const { register, handleSubmit } = useForm();

  const navigate = useNavigate();

  const [inputType, setInputType] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState();
  const [invalidLog, setInvalidLog] = useState();
  const [showModalPrivacyPolicy, setShowModalPrivacyPolicy] = useState();
  const [showModalTerms, setShowModalTerms] = useState();

  const toggleInput = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const onInputEmailChange = (event) => {
    if (event.target.value !== "") {
      setIsLoading();
      setInvalidLog();
      setEmail(event.target.value);
    } else {
      setEmail();
      setIsLoading();
      setInvalidLog();
    }
  };

  const onInputPasswordChange = (event) => {
    setPassword(event.target.value);
    setIsLoading();
    setInvalidLog();
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (email && password) {
      setInvalidLog();
      const user = await logUserDB(email, password);
      if (user && user !== "error") {
        mixpanel.track("Log in", {
          user: user.uid,
        });
        mixpanel.identify(user.uid);
        mixpanel.people.set({ email: user.email });
        navigate("/log-book", {
          state: {
            user: {
              id: user.uid,
              email: user.email,
              name: user.displayName,
              password: password,
            },
          },
        });
      } else if (user === "error") {
        setInvalidLog(true);
        setIsLoading();
      }
    } else if (!email || !password) {
      setInvalidLog(true);
      setIsLoading();
    }
  };

  return (
    <div>
      <div className="login flex items-center justify-center h-screen">
        {showModalPrivacyPolicy && (
          <ModalPrivacyPolicy
            showModalPrivacyPolicy={showModalPrivacyPolicy}
            setShowModalPrivacyPolicy={setShowModalPrivacyPolicy}
          />
        )}
        {showModalTerms && (
          <ModalTerms
            showModalTerms={showModalTerms}
            setShowModalTerms={setShowModalTerms}
          />
        )}
        <div className="absolute top-10 left-11">
              <img src="/assets/login-title.svg" alt="login" />
            </div>
        <div className="flex items-center login-div w-full">
          <div className="flex flex-col w-1/2">
          </div>
          {!showModalTerms && !showModalPrivacyPolicy && (
            <div className="flex flex-col w-1/2">
              <div className="bg-[white] form-div flex flex-col sm:w-2/4 lg:w-3-5 xl:w-7/12">
                <div className="w-full h-full">
                  <p className="login-form-title text-login text-colorBgPrimary cursor-default">
                    Log in
                  </p>
                  <form
                    className="flex flex-col login-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="flex opacity-0">
                      <input
                        className="text-web-xsmall w-0 h-0"
                        type="text"
                        placeholder="username@gmail.com"
                        {...register("emailOpacity")}
                      />
                      <input
                        className="text-web-xsmall w-0 h-0"
                        type="password"
                        placeholder="username@gmail.com"
                        {...register("passwordOpacity")}
                      />
                    </div>
                    <label className="text-web-xsmall mb-2 text-colorBgPrimary">
                      Email
                    </label>
                    <div
                      className="text-web-xsmall flex items-center login-input-email-div"
                      style={{
                        border:
                          invalidLog && "1.5px solid var(--secondary-error)",
                        color: invalidLog && "var(--secondary-error)",
                        borderRadius: "6px",
                      }}
                    >
                      <input
                        className="text-web-xsmall login-inputs"
                        type="text"
                        placeholder="username@gmail.com"
                        {...register("email", {
                          // required: true,
                          // pattern: /^\S+@\S+$/i,
                        })}
                        onChange={onInputEmailChange}
                        // value={email}
                      />
                    </div>
                    <label className="text-web-xsmall mt-10 mb-2 text-colorBgPrimary">
                      Password
                    </label>
                    <div className="flex items-center">
                      <input
                        style={{
                          border:
                            invalidLog && "1.5px solid var(--secondary-error)",
                          borderRight: invalidLog && "0",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                          color: invalidLog && "var(--secondary-error)",
                        }}
                        className="text-web-xsmall login-inputs"
                        type={inputType}
                        placeholder="•••••••••"
                        {...register("password")}
                        onChange={onInputPasswordChange}
                        // value={password}
                      />
                      <div
                        style={{
                          border:
                            invalidLog && "1.5px solid var(--secondary-error)",
                          borderLeft: invalidLog && "0",
                          color: invalidLog && "var(--secondary-error)",
                        }}
                        className="password-eye-div bg-colorTextWhite flex items-center justify-center"
                      >
                        {inputType === "password" && (
                          <img
                            onClick={toggleInput}
                            src="/assets/eye.svg"
                            className="cursor-pointer"
                            alt="Show Password"
                          ></img>
                        )}
                        {inputType === "text" && (
                          <img
                            onClick={toggleInput}
                            src="/assets/eye-hide.svg"
                            className="cursor-pointer"
                            alt="Show Password"
                          ></img>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between w-full pt-4 pb-10">
                      <div className="w-full h-10">
                        {invalidLog && (
                          <p className="text-xsmall-privacy-bold text-colortTextSecondaryError">
                            The email or the password you entered is not
                            correct, please try again.
                          </p>
                        )}
                        {/* {!password && !invalidLog && (
                          <p className="text-xsmall-privacy text-colorBgPrimary">
                            For security reasons, please{" "}
                            <b>use 8 or more characters</b> with a{" "}
                            <b>mix of letters, numbers & symbols.</b>
                          </p>
                        )} */}
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      {(!password || !email) && !invalidLog && !isLoading && (
                        <button
                          className="button-black text-web-button cursor-pointer mt-1"
                          type="button"
                        >
                          Log in
                        </button>
                      )}
                      {password && email && !invalidLog && !isLoading && (
                        <button
                          className="button-black-log-in text-web-button mt-1"
                          type="submit"
                        >
                          Log in
                        </button>
                      )}
                      {invalidLog && !isLoading && (
                        <button
                          className="button-error text-web-button"
                          type="submit"
                        >
                          Log in
                        </button>
                      )}
                      {isLoading && (
                        <button
                          className="button-black-log-in cursor-default mt-1"
                          type="submit"
                        >
                          <LoadingSpinnerBlack />
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>

        <LoginFooter
          showModalPrivacyPolicy={showModalPrivacyPolicy}
          setShowModalPrivacyPolicy={setShowModalPrivacyPolicy}
          setShowModalTerms={setShowModalTerms}
          showModalTerms={showModalTerms}
        />
      </div>
    </div>
  );
};
export default Login;
