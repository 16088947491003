import LogBookDropdowImageCategories from "./log-book-dropdown-image-categories";

const LogBookImageText = ({
  imageInfo,
  floorName,
  projectName,
  user,
  setUserImages,
  setShowModalChangeCategory,
  newStateImage
}) => {
  return (
    <div className="log-book-image-info-text-div">
      <div className="flex justify-between w-full log-book-image-info-text-item">
        <span className="text-xxsmall text-colorBgPrimary opacity-60 mt-1 cursor-default">
          Project name:
        </span>
        <span className="text-xxsmall text-colorBgPrimary text-right mt-1 cursor-default">
          {projectName}
        </span>
      </div>

      <div className="flex justify-between w-full log-book-image-info-text-item">
        <span className="text-xxsmall text-colorBgPrimary opacity-60 mt-1 cursor-default">
          Category:
        </span>

        <LogBookDropdowImageCategories
          setUserImages={setUserImages}
          user={user}
          imageInfo={imageInfo}
          setShowModalChangeCategory={setShowModalChangeCategory}
          newStateImage={newStateImage}
        />
      </div>

      <div className="flex justify-between w-full log-book-image-info-text-item">
        <span className="text-xxsmall text-colorBgPrimary opacity-60 mt-1 cursor-default">
          Area:
        </span>
        <span className="text-xxsmall text-colorBgPrimary mt-1 cursor-default">
          {floorName}
        </span>
      </div>

      <div className="flex justify-between w-full log-book-image-info-text-item">
        <span className="text-xxsmall text-colorBgPrimary opacity-60 mt-1 cursor-default">
          Date:
        </span>
        <span className="text-xxsmall text-colorBgPrimary mt-1 cursor-default">
          {imageInfo?.date}
        </span>
      </div>
    </div>
  );
};

export default LogBookImageText;
