const LogBookFooter = ({
  showModalPrivacyPolicy,
  setShowModalPrivacyPolicy,
  showModalTerms,
  setShowModalTerms,
  showUserProfile,
  setImageId,
}) => {
  return (
    <div
      className="flex bg-[#F1F3F6] footer-log-book-div items-center w-full justify-between pr-10 pl-9"
      style={{
        background: showUserProfile ? "rgba(37, 39, 45, 0.25)" : null,
        pointerEvents: showUserProfile ? "none" : null,
        opacity: showUserProfile && "0.35",
        borderTop: showUserProfile && "10px solid transparent",
      }}
    >
      <div className="flex items-center h-full">
        <span
          className="text-footer cursor-pointer text-colorBgPrimary"
          onClick={() => setImageId()}
        >
          mylogbookapp.com
        </span>
        {/* <span className="pl-2 text-xsmall-privacy text-colorBgPrimary cursor-default">
          Build: 9
        </span> */}
      </div>
      <div className="flex items-center h-full">
        <p
          className="mr-6 text-footer cursor-pointer text-colorBgPrimary"
          onClick={() => {
            setShowModalPrivacyPolicy(!showModalPrivacyPolicy);
          }}
        >
          Privacy
        </p>
      </div>
    </div>
  );
};
export default LogBookFooter;
