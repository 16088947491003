import { useEffect, useState } from "react";
import "../styles/log-book-filters.css";

const LogBookFilterStatus = ({
  setStatusFilter,
  statusFilter,
  // setImagesSelectedCheckBox,
}) => {
  useEffect(() => {
    let existStatus1 = false;
    let existStatus2 = false;
    let existStatus3 = false;
    // eslint-disable-next-line array-callback-return
    statusFilter.map((filter) => {
      if (filter === 1) {
        existStatus1 = true;
      }
      if (filter === 2) {
        existStatus2 = true;
      }
      if (filter === 3) {
        existStatus3 = true;
      }
    });
    if (existStatus1) {
      setStatus1(true);
    } else {
      setStatus1();
    }
    if (existStatus2) {
      setStatus2(true);
    } else {
      setStatus2();
    }
    if (existStatus3) {
      setStatus3(true);
    } else {
      setStatus3();
    }
  }, [statusFilter]);

  const [status1, setStatus1] = useState();
  const [status2, setStatus2] = useState();
  const [status3, setStatus3] = useState();

  const handleChangeStatusFilter = (field) => {
    if (field === "status1") {
      if (!status1) {
        setStatusFilter([...statusFilter, 1]);
      } else {
        setStatusFilter(statusFilter.filter((status) => status !== 1));
      }
      setStatus1(!status1);
    }
    if (field === "status2") {
      if (!status2) {
        setStatusFilter([...statusFilter, 2]);
      } else {
        setStatusFilter(statusFilter.filter((status) => status !== 2));
      }
      setStatus2(!status2);
    }
    if (field === "status3") {
      if (!status3) {
        setStatusFilter([...statusFilter, 3]);
      } else {
        setStatusFilter(statusFilter.filter((status) => status !== 3));
      }
      setStatus3(!status3);
    }
    document.getElementById(`log-book-checkbox`).checked = false;
    // setImagesSelectedCheckBox([]);
  };

  return (
    <div className="flex items-center justify-between pt-1 logbook-filter-status-buttons">
      <div className="flex flex-col justify-center items-center mr-1">
        <button
          title="Check"
          onClick={() => {
            handleChangeStatusFilter("status1");
          }}
          className="button-filter-status"
          style={{ background: status1 ? "#E7BC49" : "#F1F3F6", width: "83px" }}
          onMouseOver={(e) =>
            (e.currentTarget.src = status1
              ? (e.currentTarget.style.background = "#CCA02A")
              : (e.currentTarget.style.background = "#E8E9ED"))
          }
          onMouseLeave={(e) =>
            (e.currentTarget.src = status1
              ? (e.currentTarget.style.background = "#E7BC49")
              : (e.currentTarget.style.background = "#F1F3F6"))
          }
        >
          <div className="flex items-center">
            <img
              title="Check"
              className="log-book-filter-status-icon cursor-pointer"
              style={{marginLeft:"16.38px", marginRight:"4.38px"}}
              src={
                status1
                  ? "/assets/rectangle-white.svg"
                  : "/assets/rectangle-grey.svg"
              }
              alt="rectangle"
            />
            <span
              className="text-xxxsmall mt-1"
              style={{ color: status1 ? "#FFFFFF" : "#505059" }}
            >
              Check
            </span>
          </div>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center mr-1">
        <button
          title="Progress"
          onClick={() => {
            handleChangeStatusFilter("status2");
          }}
          className="button-filter-status"
          style={{ background: status2 ? "#3569E3" : "#F1F3F6", width: "96px" }}
          onMouseOver={(e) =>
            (e.currentTarget.src = status2
              ? (e.currentTarget.style.background = "#2250BC")
              : (e.currentTarget.style.background = "#E8E9ED"))
          }
          onMouseLeave={(e) =>
            (e.currentTarget.src = status2
              ? (e.currentTarget.style.background = "#3569E3")
              : (e.currentTarget.style.background = "#F1F3F6"))
          }
        >
          <div className="flex items-center">
            <img
              title="Check"
              className="log-book-filter-status-icon cursor-pointer"
              style={{marginLeft:"16.38px", marginRight:"6.38px"}}
              src={
                status2
                  ? "/assets/ellipse-white.svg"
                  : "/assets/ellipse-grey.svg"
              }
              alt="ellipse"
            />
            <span
              className="text-xxxsmall mt-1"
              style={{ color: status2 ? "#FFFFFF" : "#505059" }}
            >
              Progress
            </span>
          </div>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center">
        <button
          title="Issue"
          onClick={() => {
            handleChangeStatusFilter("status3");
          }}
          className="button-filter-status"
          style={{ background: status3 ? "#D15531" : "#F1F3F6", width: "80px" }}
          onMouseOver={(e) =>
            (e.currentTarget.src = status3
              ? (e.currentTarget.style.background = "#A2381A")
              : (e.currentTarget.style.background = "#E8E9ED"))
          }
          onMouseLeave={(e) =>
            (e.currentTarget.src = status3
              ? (e.currentTarget.style.background = "#D15531")
              : (e.currentTarget.style.background = "#F1F3F6"))
          }
        >
          <div className="flex items-center">
            <img
              title="Check"
              style={{marginLeft:"15.25px", marginRight:"6.25px"}}
              className="log-book-filter-status-icon cursor-pointer"
              src={
                status3
                  ? "/assets/polygon-white.svg"
                  : "/assets/polygon-grey.svg"
              }
              alt="polygon"
            />
            <span
              className="text-xxxsmall mt-1"
              style={{ color: status3 ? "#FFFFFF" : "#505059" }}
            >
              Issue
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default LogBookFilterStatus;
