import { useState } from "react";
import "../styles/profile.css";
import LogBookUserProfileSecurityChangePassword from "./log-book-user-profile-security-change-password";
const LogBookUserProfileSecurity = ({
  showSecurityProfile,
  setShowSecurityProfile,
  userInfo,
  setPassword,
  password,
  setShowModalChangesOk,
}) => {
  const [userEmail] = useState(userInfo.email);
  const [showPassword, setShowPassword] = useState();
  const [inputType, setInputType] = useState("password");
  const [showChangePassword, setShowChangePassword] = useState();

  return (
    <div className="w-full h-[calc(100%)]">
      {!showChangePassword && (
        <div className="w-full h-[calc(100%)]">
          <div className="profile-title-div flex flex-col pt-9 pb-10">
            <div className="flex items-center">
              <img
                onClick={() => setShowSecurityProfile(!showSecurityProfile)}
                src="/assets/left.svg"
                alt="back"
                className="pl-11 pb-1 pr-24 mb-2.5 cursor-pointer"
              />
              <p className="text-h5 w-full profile-title-name text-colorBgPrimary cursor-default">Security</p>
            </div>
            <div className="flex w-full justify-center items-center">
              <p className="text-web-xsmall w-full text-center w-2/3 text-colorBgPrimary cursor-default">
                Manage how you access to your Logbook account.
              </p>
            </div>
          </div>
          <div className="px-9 flex pt-8 pb-6">
            <div className="flex flex-col w-full ">
              <p className="text-web-xsmall-bold mb-2.5 text-colorBgPrimary cursor-default">Email</p>
              <div className="flex items-center bg-[white] w-full rounded-lg mb-8">
                <input
                  className="text-web-xsmall py-2 pl-4 h-11 w-10/12 bg-[white] rounded-lg outline-0"
                  value={userEmail}
                  disabled
                  type="text"
                />
              </div>
              <div id="user-profile-email-div" className="flex flex-col w-full">
                <p className="text-web-xsmall-bold mb-2.5 text-colorBgPrimary cursor-default">Password</p>
                <div className="flex items-center bg-[white] w-full rounded-lg mb-4">
                  <input
                    className="text-web-xsmall py-2 pl-4 h-11 w-10/12 bg-[white] rounded-lg outline-0"
                    value={password}
                    type={inputType}
                    disabled
                  />
                  <div className="flex h-11 items-center w-2/12 pr-4 justify-end">
                    <img
                      className="cursor-pointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                        inputType === "text"
                          ? setInputType("password")
                          : setInputType("text");
                      }}
                      src={
                        !showPassword
                          ? "/assets/eye.svg"
                          : "/assets/eye-hide.svg"
                      }
                      alt="password"
                    />
                  </div>
                </div>
                <div className="text-right text-web-xsmall-bold text-colorTextPrimary">
                  <p
                    className="cursor-pointer"
                    onClick={() => setShowChangePassword(true)}
                  >
                    Change Password
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showChangePassword && (
        <LogBookUserProfileSecurityChangePassword
          userInfo={userInfo}
          showChangePassword={showChangePassword}
          setShowChangePassword={setShowChangePassword}
          setShowModalChangesOk={setShowModalChangesOk}
          setPassword={setPassword}
        />
      )}
    </div>
  );
};
export default LogBookUserProfileSecurity;
