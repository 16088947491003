import Modal from "react-modal";
import "../styles/modal-login.css";
const ModalPrivacyPolicy = ({
  showModalPrivacyPolicy,
  setShowModalPrivacyPolicy,
}) => {
  const closeModal = () => {
    setShowModalPrivacyPolicy();
  };
  const customStyles = {
    content: {
      background: "white",
      color: "#25272d",
      width: "469px",
      height: "465px",
      top: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
      overflowY: "hidden",
    },
    overlay: { zIndex: 1000, background: "rgba(0, 0, 0, 0.6)" },
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModalPrivacyPolicy}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col justify-center items-center h-full">
          <div className="flex w-full justify-between items-center mt-6">
            <p className="text-big">Privacy Policy</p>
            <img
              className="cursor-pointer"
              onClick={() => setShowModalPrivacyPolicy()}
              src="/assets/close-modal.svg"
              alt="close"
            />
          </div>
          <div className="modal-login-text h-96 overflow-y-scroll mt-10 overflow-styles">
            <p className="text-xxsmall pr-3">
              The undersigned states and declares that the attached document
              does not violate any intellectual property right or any sensitive
              industrial data, expressly exonerating Nacar Estudio, S.L. of any
              responsibility concerning such matters.
            </p>
            <p className="text-xxsmall mt-6 pr-3">
              By using this application, you provide consent to Nacar Design to
              share material internally between researchers with whom “Logbook”
              project collaborates. All the information provided for this study
              will be treated conﬁdentially and for private use only.
            </p>
            <p className="text-xxsmall mt-6 pr-3">
              The images shared by you on this platform might be shared with
              “Logbook” third parties with the sole objective of testing and
              improving the solution currently under development, and avoiding
              sharing your data.
            </p>
            <p className="text-xxsmall mt-6 pr-3">
              No information will be shared with third parties for any purposes
              beyond this scope.
            </p>
            <p className="text-xxsmall mt-6 pr-3">
              We reserve the right to modify this privacy policy at any time, so
              please review it frequently. Changes and clarifications will take
              effect immediately upon their posting on the website. If we make
              material changes to this policy, we will notify you here that it
              has been updated, so that you are aware of what information we
              collect, how we use it, and under what circumstances, if any, we
              use and/or disclose it. If you would like to: access, correct,
              amend or delete any personal information we have about you, you
              are invited to contact us at <a className="text-colorTextPrimary" href={"mailto: info@nacardesign.com"}>info@nacardesign.com</a>.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalPrivacyPolicy;
