import Modal from "react-modal";
import mixpanel from "mixpanel-browser";
const ModalDeleteImage = ({
  showModalDeleteImage,
  setShowModalDeleteImage,
  deleteImages,
  imageInfo,
  imagesSelectedCheckBox,
}) => {
  const closeModal = () => {
    setShowModalDeleteImage();
  };
  const customStyles = {
    content: {
      background: "white",
      color: "#25272d",
      width: "300px",
      height: "195px",
      top: "35%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "6px",
    },
    overlay: { zIndex: 1000, background: "rgba(0, 0, 0, 0.6)" },
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModalDeleteImage}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col">
          <div className="flex flex-col text-center">
            <p className="pt-5 text-h5 text-colorBgPrimary">Delete image</p>
            <p className="pt-2 text-xsmall text-colorBgPrimary">
              {imageInfo && "Are you sure you want to delete this photo?"}
              {imagesSelectedCheckBox?.length > 1 &&
                "Are you sure you want to delete selected photos?"}
              {imagesSelectedCheckBox?.length === 1 &&
                "Are you sure you want to delete selected photo?"}
            </p>
            <div className="pt-6 flex justify-around">
              <button
                className="button-cancel text-xsmall-bold mr-3"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="button-delete text-xsmall-bold"
                type="button"
                onClick={() => {
                  imagesSelectedCheckBox &&
                    deleteImages(imagesSelectedCheckBox);
                  imageInfo && deleteImages([{ id: imageInfo?.image?.id }]);
                  mixpanel.track("Delete pictures", {
                    "Number of images": !imageInfo
                      ? imagesSelectedCheckBox.length
                      : 1,
                  });
                  closeModal();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalDeleteImage;
