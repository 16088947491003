import { useEffect, useRef, useState } from "react";
import "../styles/dropdowns.css";
const LogBookDropdownProjects = ({
  infoArray,
  setFilter,
  filter,
  // setImagesSelectedCheckBox,
  setProjectNameSelected,
  projectNameSelected,
}) => {
  const [isList, setIsList] = useState(false);

  useEffect(() => {
    if (filter?.length === 0) {
      setProjectNameSelected({ id: "all", name: "All projects" });
    } else if (filter?.length > 0) {
      let projectNameSelectedTemp = infoArray?.filter(
        (project) => project.id === filter
      );
      if (projectNameSelectedTemp) {
        setProjectNameSelected(projectNameSelectedTemp[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  //start to close list when click outside the list
  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsList();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  //end to close list when click outside the list

  const changeStyleItemTagDiv = (action, key) => {
    if (action === "hover") {
      document.getElementById(`checkbox-project-div-${key}`).style.background =
        "#F1F3F6";
    } else if (action === "leave") {
      document.getElementById(`checkbox-project-div-${key}`).style.background =
        "white";
    }
  };

  return (
    <div className="z-10" ref={wrapperRef}>
      <div className="w-60">
        <div
          onClick={() => setIsList(!isList)}
          className="z-10 w-fit pt-3 pb-1 rounded bg-white text-xxsmall-bold  flex items-center cursor-pointer"
          style={{
            border: isList ? "1px solid #F1F3F6" : "1px solid transparent",
          }}
        >
          <div className="w-fit">
            <span className="pl-1 text-colorBgPrimary">
              {projectNameSelected?.name?.length > 14
                ? projectNameSelected?.name.substring(0, 14) + "..."
                : projectNameSelected?.name}
            </span>
          </div>
          <div className="w-fit">
            {isList ? (
              <div className="z-10  pl-2 pr-3">
                <img src="/assets/up.svg" alt="close" />
              </div>
            ) : (
              <div className="z-10 pl-2 pr-3">
                <img src="/assets/down.svg" alt="open" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isList && (
        <div className="z-10 w-60 mt-3 py-3 px-2 shadow rounded overflow-y-scroll dropdown-list overflow-styles">
          <div
            id={`checkbox-project-div-all`}
            className="z-10 flex items-center justify-between py-3 dropdown-projects-item cursor-pointer"
            style={{
              border: (!infoArray || infoArray?.length === 0) && "0",
              marginBottom: infoArray?.length === 0 && 0,
            }}
            onMouseOver={() => changeStyleItemTagDiv("hover", "all")}
            onMouseLeave={() => changeStyleItemTagDiv("leave", "all")}
            onClick={() => {
              setFilter();
              setIsList();
              setProjectNameSelected({ id: "all", name: "All projects" });
              document.getElementById(`log-book-checkbox`).checked = false;
              // setImagesSelectedCheckBox([]);
            }}
          >
            <div className="z-10 flex items-center w-full pl-2">
              <div className="z-10 flex items-center w-full">
                <div
                  style={{
                    background: !filter && "#3B82F6",
                    border: !filter
                      ? "1px solid #3B82F6"
                      : "1px solid rgba(161, 161, 170, 1)",
                  }}
                  className="z-10 rounded-xl w-3 h-3 flex flex-shrink-0 items-center"
                ></div>
                <p className="w-full z-10  text-xxsmall mt-1 ml-3.5 text-colorBgPrimary">
                  All projects
                </p>
              </div>
            </div>
          </div>
          {infoArray?.map((item, key) => {
            return (
              <div
                id={`checkbox-project-div-${key}`}
                key={key}
                className="z-10 flex items-center justify-between py-3 dropdown-projects-item cursor-pointer"
                style={{
                  border: infoArray.length === key + 1 && "0",
                  marginBottom: infoArray.length === key + 1 && 0,
                }}
                onMouseOver={() => changeStyleItemTagDiv("hover", key)}
                onMouseLeave={() => changeStyleItemTagDiv("leave", key)}
                onClick={() => {
                  setFilter(item.id);
                  setIsList();
                  setProjectNameSelected({
                    id: item.id,
                    name:
                      item.name.length > 14
                        ? item.name.substring(0, 14) + "..."
                        : item.name,
                  });
                  document.getElementById(`log-book-checkbox`).checked = false;
                  // setImagesSelectedCheckBox([]);
                }}
              >
                <div className="z-10 flex items-center w-full pl-2">
                  <div className="z-10 flex items-center w-full">
                    <div
                      style={{
                        background: filter === item.id && "#3B82F6",
                        border:
                          filter === item.id
                            ? "1px solid #3B82F6"
                            : "1px solid rgba(161, 161, 170, 1)",
                      }}
                      className="z-10 rounded-xl w-3 h-3 flex flex-shrink-0 items-center"
                    ></div>
                    <p className="w-full z-10 text-xxsmall mt-1 ml-3.5 text-colorBgPrimary">
                      {item.name.length > 14
                        ? item.name.substring(0, 14) + "..."
                        : item.name}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LogBookDropdownProjects;
