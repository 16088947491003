import { useState } from "react";
import { getUserInfoDB, updateUserProfileDB } from "../lib/db";
import "../styles/profile.css";
import LoadingSpinner from "./loading-spinner";
const LogBookUserProfileEdit = ({
  showEditProfile,
  setShowEditProfile,
  user,
  userInfo,
  setUserInfo,
  setShowModalChangesOk,
}) => {
  // const [confirmProfile, setConfirmProfile] = useState();
  const [userName, setUserName] = useState(userInfo.fullName);
  const [userEmail, setUserEmail] = useState(userInfo.email);
  const [isLoading, setIsLoading] = useState();

  const updateUserProfile = async () => {
    //check if userEmail is valid
    setIsLoading(true);
    const response = await updateUserProfileDB(user.id, userName, userEmail);
    if (response) {
      await setUserInfo(await getUserInfoDB(user.id));
      setShowModalChangesOk("Profile");
      setTimeout(() => {
        setShowModalChangesOk();
      }, 2000);
    }
    setIsLoading();
  };

  return (
    <div className="w-full h-[calc(100%-2px)]">
      <div className="profile-title-div flex  flex-col pt-9 pb-10">
        <div className="flex items-center">
          <img
            onClick={() => setShowEditProfile(!showEditProfile)}
            src="/assets/left.svg"
            alt="back"
            className="pl-11 pb-1 pr-24 mb-2.5 cursor-pointer"
          />
          <p className="text-h5 w-full profile-title-name text-colorBgPrimary cursor-default">My Profile</p>
        </div>
        <div className="flex w-full justify-center items-center">
          <p className="text-web-xsmall w-full text-center w-2/3 text-colorBgPrimary cursor-default">
            Edit your profile and personal information.
          </p>
        </div>
      </div>
      <div className="px-9 flex pt-8 h-[calc(100%-135px)]">
        <div className="flex flex-col w-full ">
          <p className="text-web-xsmall-bold mb-2.5 text-colorBgPrimary cursor-default">Name</p>
          <div className="flex items-center bg-[white] w-full rounded-lg mb-8">
            <input
              id="user-profile-name-input"
              onChange={(e) => setUserName(e.target.value)}
              className="text-web-xsmall py-2 pl-4 h-11 w-full bg-[white] rounded-lg outline-0"
              value={userName}
              type="text"
            />
          </div>
          <div
            id="user-profile-email-div"
            className="flex flex-col w-full opacity-50"
          >
            <p className="text-web-xsmall-bold mb-2.5 text-colorBgPrimary cursor-default">Email</p>
            <div className="flex items-center bg-[white] w-full rounded-lg mb-8">
              <input
                id="user-profile-email-input"
                onChange={(e) => setUserEmail(e.target.value)}
                className="text-web-xsmall py-2 pl-4 h-11 w-10/12 bg-[white] rounded-lg outline-0"
                value={userEmail}
                type="email"
              />
              <div className="flex h-11 items-center w-2/12 pr-4 justify-end">
                <img src={"/assets/locked.svg"} alt="edit" />
              </div>
            </div>
          </div>

          <div className="px-9 h-[calc(100%)] justify-center flex items-end pt-8 pb-20">
            {userName !== userInfo.fullName && !isLoading && (
              <button
                onClick={updateUserProfile}
                className="button-blue w-40 text-web-button"
                type="button"
              >
                Confirm
              </button>
            )}
            {isLoading && (
              <button
                className="button-blue w-40 text-web-button cursor-default"
                type="button"
              >
                <LoadingSpinner />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogBookUserProfileEdit;
