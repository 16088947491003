import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
const ModalLogout = ({
  showModalLogout,
  setShowModalLogout,
}) => {
  const navigate = useNavigate();
  const logOut = () => {
    navigate("/");
  };

  const closeModal = () => {
    setShowModalLogout();
  };
  const customStyles = {
    content: {
      background: "white",
      color: "#25272d",
      width: "300px",
      height: "195px",
      top: "35%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "6px",
    },
    overlay: { zIndex: 1000, background: "rgba(0, 0, 0, 0.6)" },
  };

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={showModalLogout}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col">
          <div className="flex flex-col text-center">
            <p className="pt-5 text-h5 text-colorBgPrimary">Log out</p>
            <p className="pt-2 text-xsmall text-colorBgPrimary">
              Are you sure you want to log out from your profile?
            </p>
            <div className="pt-6 flex justify-around">
              <button
                className="button-cancel text-xsmall-bold mr-3"
                type="button"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
              <button
                className="button-delete text-xsmall-bold"
                type="button"
                onClick={() => {
                  logOut();
                }}
              >
                Log out
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalLogout;
