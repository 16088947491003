import { useEffect } from "react";
import "../styles/profile.css";
import LogBookUserProfileEdit from "./log-book-user-profile-edit";
import LogBookUserProfileSecurity from "./log-book-user-profile-security";

const LogBookUserProfile = ({
  setShowUserProfile,
  showUserProfile,
  userInfo,
  user,
  setShowSecurityProfile,
  showSecurityProfile,
  setShowEditProfile,
  showEditProfile,
  setUserInfo,
  setShowModalChangesOk,
  wrapperRef,
  setPassword,
  password,
  setShowModalLogout,
  showModalLogout,
}) => {
  //start to close list when click outside the list

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !showModalLogout
        ) {
          if (document.getElementById("profile-div")) {
            document.getElementById("profile-div").style.width = "0";
          }
          setShowSecurityProfile();
          setShowEditProfile();
          // setShowUserProfile(!showUserProfile);
          setShowUserProfile();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, showModalLogout]);
  }

  useOutsideAlerter(wrapperRef);

  //end to close list when click outside the list
  return (
    <div
      ref={wrapperRef}
      id="profile-div"
      className="profile-div opacity-0 w-0 h-[calc(100%-75px)] bg-[#F1F3F6]"
    >
      {showSecurityProfile && (
        <LogBookUserProfileSecurity
          setShowSecurityProfile={setShowSecurityProfile}
          showSecurityProfile={showSecurityProfile}
          userInfo={userInfo}
          setPassword={setPassword}
          password={password}
          setShowModalChangesOk={setShowModalChangesOk}
        />
      )}
      {showEditProfile && (
        <LogBookUserProfileEdit
          setShowEditProfile={setShowEditProfile}
          showEditProfile={showEditProfile}
          user={user}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setShowModalChangesOk={setShowModalChangesOk}
        />
      )}
      {showUserProfile && !showSecurityProfile && !showEditProfile && (
        <div className="w-full h-full">
          <div className="profile-title-div profile-title-div-padding flex flex-col pt-11">
            <p className="text-h5 w-full text-center profile-title-name text-colorBgPrimary cursor-default">
              Hello, {userInfo.fullName}
            </p>
            <p className="text-web-xsmall w-full text-center text-colorBgPrimary cursor-default">
              Manage your Account & Preferences
            </p>
          </div>
          <div
            className="profile-title-div flex items-center justify-center pt-8 pb-6 cursor-pointer"
            onClick={() => setShowEditProfile(true)}
          >
            <div className="mr-4">
              <img src="/assets/profile-icon.svg" alt="Profile" />
            </div>
            <div className="flex flex-col">
              <p className="text-xsmall-bold text-colorBgPrimary">My Profile</p>
              <p className="text-web-xsmall text-colorBgPrimary">
                Edit your profile and personal information.
              </p>
            </div>
          </div>
          <div
            className="flex items-center justify-center pt-8 pb-6 cursor-pointer"
            onClick={() => setShowSecurityProfile(true)}
          >
            <div className="mr-4">
              <img src="/assets/security-icon.svg" alt="Profile" />
            </div>
            <div className="flex flex-col">
              <p className="text-xsmall-bold text-colorBgPrimary">Security & Password</p>
              <p className="text-web-xsmall text-colorBgPrimary">
                Manage how you access to your Logbook
              </p>
              <p className="text-web-xsmall text-colorBgPrimary">account.</p>
            </div>
          </div>
          <div className="px-9 h-[calc(100%-350px)] justify-center flex items-end pt-8 pb-20">
            <button
              onClick={() => {
                setShowModalLogout(true);
              }}
              className="button-error text-web-button"
              type="button"
            >
              Log out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default LogBookUserProfile;
