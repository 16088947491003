/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteImagesDB,
  filterImagesDB,
  getFloorsInfoDB,
  getProjectsInfoDB,
  getUserImagesDB,
  getUserInfoDB,
  getUserTagsDB,
} from "../lib/db";
import "../styles/log-book.css";
import LogbookHeader from "./log-book-header";
import LogbookFilters from "./log-book-filters";
import LogBookImages from "./log-book-images";
import ModalDeleteImage from "./modalDeleteImage";
import LogBookUserProfile from "./log-book-user-profile";
import ModalChangesOK from "./modalChangesOk";
import ModalWelcome from "./modalWelcome";
import LogBookFooter from "./log-book-footer";
import ModalPrivacyPolicy from "./modalPrivacyPolicy";
import ModalTerms from "./modalTerms";
import ModalLogout from "./modalLogout";
import LogBookImage from "./log-book-image";
import ModalChangeCategory from "./modalChangeCategory";
import mixpanel from "mixpanel-browser";

const LogBook = () => {
  const location = useLocation();
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [userImages, setUserImages] = useState();
  const [userImagesStatusFiltered, setUserImagesStatusFiltered] = useState();
  const [userImagesTagsFiltered, setUserImagesTagsFiltered] = useState();
  const [imagesSelectedCheckBox, setImagesSelectedCheckBox] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [userTags, setUserTags] = useState();
  const [userProjects, setUserProjects] = useState();
  const [imageId, setImageId] = useState();
  const [floorsInfo, setFloorsInfo] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isLoadingImages, setIsLoadingImages] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [projectIdFilter, setProjectIdFilter] = useState();
  const [floorLocationIdsFilter, setFloorLocationIdsFilter] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [showModalDeleteImage, setShowModalDeleteImage] = useState();
  const [showUserProfile, setShowUserProfile] = useState();
  const [showSecurityProfile, setShowSecurityProfile] = useState();
  const [showEditProfile, setShowEditProfile] = useState();
  const [showModalChangesOk, setShowModalChangesOk] = useState();
  const [showModalWelcome, setShowModalWelcome] = useState();
  const [showModalLogout, setShowModalLogout] = useState();
  const [showModalChangeCategory, setShowModalChangeCategory] = useState();
  const [profileIsClicked, setProfileIsClicked] = useState();
  const [projectNameSelected, setProjectNameSelected] = useState({
    id: "all",
    name: "All projects",
  });
  const [showModalPrivacyPolicy, setShowModalPrivacyPolicy] = useState();
  const [showModalTerms, setShowModalTerms] = useState();
  const [imageInfo, setImageInfo] = useState();
  const [windowWidth, setWindowWidth] = useState(
    document.body.clientWidth > 1920 ? 1920 : document.body.clientWidth
  );
  const [newStateImage, setNewStateImage] = useState();

  // start observing a DOM node

  const wrapperRef = useRef();
  const navigate = useNavigate();

  window.addEventListener("resize", () =>
    setWindowWidth(
      document.body.clientWidth > 1920 ? 1920 : document.body.clientWidth
    )
  );

  useEffect(() => {
    if (location?.state?.user) {
      setUser(location.state.user);
      setPassword(location.state.user.password);
    } else {
      navigate("/");
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (userInfo?.showWelcomeModal) {
      setShowModalWelcome(userInfo.showWelcomeModal);
    }
  }, [userInfo?.showWelcomeModal]);

  useEffect(() => {
    if (floorLocationIdsFilter?.length >= 2 && statusFilter?.length === 2) {
      filterImagesByStatus();
    }
  }, [userImages, statusFilter, floorLocationIdsFilter]);

  useEffect(() => {
    if (tagsFilter?.length >= 1) {
      filterImagesByTags();
    } else {
      setUserImagesTagsFiltered();
    }
  }, [userImages, userImagesStatusFiltered, tagsFilter]);

  useEffect(() => {
    if (projectIdFilter || floorLocationIdsFilter || statusFilter) {
      filterImages();
    }
  }, [projectIdFilter, floorLocationIdsFilter, statusFilter, tagsFilter]);

  useEffect(() => {
    if (
      (floorLocationIdsFilter?.length > 0 &&
        floorLocationIdsFilter?.length !== floorsInfo?.length) ||
      (statusFilter?.length > 0 && statusFilter?.length !== 3) ||
      tagsFilter.length > 0
    ) {
      mixpanel.track("Gallery search", {
        "Category Filter Used":
          statusFilter?.length > 0 && statusFilter?.length !== 3 ? true : false,
        "Levels Filter Used":
          floorLocationIdsFilter?.length > 0 &&
          floorLocationIdsFilter?.length !== floorsInfo?.length
            ? true
            : false,
        "Tags Filter Used": tagsFilter?.length > 0 ? true : false,
      });
    }
    if (
      floorLocationIdsFilter?.length > 0 &&
      floorLocationIdsFilter?.length !== floorsInfo?.length
    ) {
      mixpanel.track("Filter used level");
    }
    if (statusFilter?.length !== 3 && statusFilter?.length > 0) {
      mixpanel.track("Filter used category");
    }
    if (tagsFilter?.length > 0) {
      mixpanel.track("Filter used tag");
    }
  }, [floorLocationIdsFilter, statusFilter, tagsFilter]);

  const getData = async () => {
    setIsLoading(true);
    setUserInfo(await getUserInfoDB(user.id));
    setUserImages(await getUserImagesDB(user.id));
    setUserProjects(await getProjectsInfoDB(user.id));
    setFloorsInfo(await getFloorsInfoDB());
    setUserTags(await getUserTagsDB(user.id));
    setIsLoading();
  };

  const checkElementsinArray = (tagsArray, filterArray) => {
    return filterArray.every((elem) => tagsArray?.includes(elem));
  };

  const filterImagesByTags = () => {
    let imageTagsTemp = [];

    if (userImagesStatusFiltered?.length > 0) {
      userImagesStatusFiltered?.map(
        (image) =>
          checkElementsinArray(image.tags, tagsFilter) &&
          imageTagsTemp.push(image)
      );
      setUserImagesTagsFiltered(imageTagsTemp);
    } else {
      userImages?.map(
        (image) =>
          checkElementsinArray(image.tags, tagsFilter) &&
          imageTagsTemp.push(image)
      );
      setUserImagesTagsFiltered(imageTagsTemp);
    }
  };
  const filterImagesByStatus = () => {
    setUserImagesStatusFiltered(
      userImages?.filter((image) => statusFilter?.includes(image.status))
    );
  };

  const filterImages = async () => {
    setUserImagesStatusFiltered();
    setUserImages();
    setIsLoadingImages(true);
    const filterImages = await filterImagesDB(
      user?.id,
      statusFilter,
      projectIdFilter,
      floorLocationIdsFilter,
      floorsInfo
    );
    if (filterImages.length > 0) {
      setUserImages(filterImages);
    }
    setIsLoadingImages();
  };

  const deleteImages = async (images) => {
    if (images.length > 0) {
      await deleteImagesDB(user.id, images);
      setImageId();
      // setImagesSelectedCheckBox([]);
      // eslint-disable-next-line array-callback-return
      userImages?.map((image, key) => {
        if (document.getElementById(`log-book-image-${key}-checkbox`)) {
          document.getElementById(
            `log-book-image-${key}-checkbox`
          ).checked = false;
        }
      });
      if (document.getElementById(`log-book-checkbox`)) {
        document.getElementById(`log-book-checkbox`).checked = false;
      }

      setUserImages(await getUserImagesDB(user.id));
      filterImages();
    }
  };

  return (
    <div className="flex flex-col h-full">
      {showModalChangeCategory && (
        <ModalChangeCategory
          setShowModalChangeCategory={setShowModalChangeCategory}
          user={user}
          imagesSelectedCheckBox={imagesSelectedCheckBox}
          showModalChangeCategory={showModalChangeCategory}
          setUserImages={setUserImages}
          setNewStateImage={setNewStateImage}
          projectIdFilter={projectIdFilter}
          floorLocationIdsFilter={floorLocationIdsFilter}
          statusFilter={statusFilter}
          filterImages={filterImages}
        />
      )}
      {showModalLogout && (
        <ModalLogout
          showModalLogout={showModalLogout}
          setShowModalLogout={setShowModalLogout}
          setShowUserProfile={setShowUserProfile}
        />
      )}
      {showModalPrivacyPolicy && (
        <ModalPrivacyPolicy
          showModalPrivacyPolicy={showModalPrivacyPolicy}
          setShowModalPrivacyPolicy={setShowModalPrivacyPolicy}
        />
      )}
      {showModalTerms && (
        <ModalTerms
          showModalTerms={showModalTerms}
          setShowModalTerms={setShowModalTerms}
        />
      )}
      {showModalWelcome && !showModalChangesOk && (
        <ModalWelcome
          showModalWelcome={showModalWelcome}
          setShowModalWelcome={setShowModalWelcome}
          user={user}
        />
      )}
      {showModalDeleteImage && (
        <ModalDeleteImage
          showModalDeleteImage={showModalDeleteImage}
          setShowModalDeleteImage={setShowModalDeleteImage}
          imagesSelectedCheckBox={imagesSelectedCheckBox}
          deleteImages={deleteImages}
        />
      )}
      {showModalChangesOk && (
        <ModalChangesOK
          setShowModalChangesOk={setShowModalChangesOk}
          showModalChangesOk={showModalChangesOk}
        />
      )}
      {!isLoading && user && (
        <div className="w-full h-[calc(100%)]">
          <LogbookHeader
            userInfo={userInfo}
            showUserProfile={showUserProfile}
            setShowUserProfile={setShowUserProfile}
            wrapperRef={wrapperRef}
            profileIsClicked={profileIsClicked}
            setProfileIsClicked={setProfileIsClicked}
            setImageId={setImageId}
          />

          <LogBookUserProfile
            setShowUserProfile={setShowUserProfile}
            showUserProfile={showUserProfile}
            userInfo={userInfo}
            user={user}
            setShowSecurityProfile={setShowSecurityProfile}
            setShowEditProfile={setShowEditProfile}
            showSecurityProfile={showSecurityProfile}
            showEditProfile={showEditProfile}
            setUserInfo={setUserInfo}
            setShowModalChangesOk={setShowModalChangesOk}
            wrapperRef={wrapperRef}
            setPassword={setPassword}
            password={password}
            setShowModalLogout={setShowModalLogout}
            showModalLogout={showModalLogout}
          />

          <div
            className="flex flex-col w-full h-[calc(100%-75px)]"
            style={{
              background: showUserProfile ? "rgba(37, 39, 45, 0.25)" : null,
              pointerEvents: showUserProfile ? "none" : null,
              opacity: showUserProfile && "0.35",
            }}
          >
            <div>
              {!imageId && (
                <div
                  className="log-book-filters-div flex w-full items-center z-10"
                  style={{
                    background: showUserProfile
                      ? "rgba(37, 39, 45, 0.0)"
                      : null,
                    pointerEvents: showUserProfile ? "none" : null,
                    opacity: showUserProfile && "0.35",
                  }}
                >
                  <LogbookFilters
                    userProjects={userProjects}
                    floorsInfo={floorsInfo}
                    projectIdFilter={projectIdFilter}
                    floorLocationIdsFilter={floorLocationIdsFilter}
                    statusFilter={statusFilter}
                    setProjectIdFilter={setProjectIdFilter}
                    setStatusFilter={setStatusFilter}
                    setFloorLocationIdsFilter={setFloorLocationIdsFilter}
                    tagsFilter={tagsFilter}
                    setTagsFilter={setTagsFilter}
                    userImages={
                      userImagesTagsFiltered
                        ? userImagesTagsFiltered
                        : userImagesStatusFiltered
                        ? userImagesStatusFiltered
                        : userImages
                    }
                    setImagesSelectedCheckBox={setImagesSelectedCheckBox}
                    imagesSelectedCheckBox={imagesSelectedCheckBox}
                    setShowModalDeleteImage={setShowModalDeleteImage}
                    userTags={userTags}
                    setProjectNameSelected={setProjectNameSelected}
                    projectNameSelected={projectNameSelected}
                    showModalDeleteImage={showModalDeleteImage}
                    setUserImages={setUserImages}
                    user={user}
                    setShowModalChangeCategory={setShowModalChangeCategory}
                  />
                </div>
              )}
              <div
                className="flex justify-center w-full h-full min-h-[calc(100vh-303px)]"
                style={{ padding: !imageId && "0px 16px" }}
              >
                {!imageId && (
                  <LogBookImages
                    userProjects={userProjects}
                    floorsInfo={floorsInfo}
                    imageId={imageId}
                    setImageId={setImageId}
                    userImages={
                      userImagesTagsFiltered
                        ? userImagesTagsFiltered
                        : userImagesStatusFiltered
                        ? userImagesStatusFiltered
                        : userImages
                    }
                    setImagesSelectedCheckBox={setImagesSelectedCheckBox}
                    imagesSelectedCheckBox={imagesSelectedCheckBox}
                    deleteImages={deleteImages}
                    setShowModalDeleteImage={setShowModalDeleteImage}
                    showModalDeleteImage={showModalDeleteImage}
                    projectNameSelected={projectNameSelected}
                    setImageInfo={setImageInfo}
                    windowWidth={windowWidth}
                  />
                )}

                {imageId && (
                  <LogBookImage
                    imageInfo={imageInfo}
                    setImageId={setImageId}
                    userProjects={userProjects}
                    floorsInfo={floorsInfo}
                    deleteImages={deleteImages}
                    setShowModalDeleteImage={setShowModalDeleteImage}
                    showModalDeleteImage={showModalDeleteImage}
                    projectNameSelected={projectNameSelected}
                    user={user}
                    setUserImages={setUserImages}
                    setShowModalChangeCategory={setShowModalChangeCategory}
                    newStateImage={newStateImage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {isLoadingImages && !isLoading && (
        <div className="pt-10">
          <LoadingSpinner />
        </div>
      )} */}
      {!isLoading && !imageId && !isLoadingImages && user && (
        <LogBookFooter
          showModalPrivacyPolicy={showModalPrivacyPolicy}
          setShowModalPrivacyPolicy={setShowModalPrivacyPolicy}
          setShowModalTerms={setShowModalTerms}
          showModalTerms={showModalTerms}
          showUserProfile={showUserProfile}
          setImageId={setImageId}
        />
      )}
    </div>
  );
};

export default LogBook;
