import { useEffect, useRef, useState } from "react";
import "../styles/dropdowns.css";
const LogBookDropdownFloors = ({
  infoArray,
  setFilter,
  filter,
  // setImagesSelectedCheckBox,
}) => {
  const [isList, setIsList] = useState(false);
  const [floorName, setFloorName] = useState("All areas");
  //start to close list when click outside the list
  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsList(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  //end to close list when click outside the list
  useEffect(() => {
    if (isList) {
      let floorIds = [];
      infoArray?.map((floor) => floorIds.push(floor.id));
      if (filter?.length === infoArray?.length) {
        for (let i = 0; i < infoArray.length; i++) {
          document.getElementById(`checkbox-project-${i}`).checked = true;
          document.getElementById(`checkbox-all`).checked = true;
        }
      } else if (filter?.length > 0) {
        // eslint-disable-next-line array-callback-return
        filter?.map((f) => {
          for (let i = 0; i < infoArray?.length; i++) {
            if (document.getElementById(`checkbox-project-${i}`)?.value === f) {
              document.getElementById(`checkbox-project-${i}`).checked = true;
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isList]);

  useEffect(() => {
    if (filter?.length <= 0 || filter?.length === infoArray.length) {
      setFloorName("All areas");
    } else if (filter?.length === 1) {
      setFloorName(getNameById(filter[0]));
    } else {
      setFloorName(filter.length + (filter.length > 1 ? "x Areas" : "x Area"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const getNameById = (id) => {
    return infoArray?.filter((i) => i.id === id)[0]?.name;
  };

  const enableDropDownItems = () => {
    for (let i = 0; i < infoArray.length; i++) {
      document.getElementById(`dropdown-area-item-div-${i}`).style.opacity =
        "1";
      document.getElementById(
        `dropdown-area-item-div-${i}`
      ).style.pointerEvents = "auto";
    }
  };
  const disableDropDownItems = () => {
    for (let i = 0; i < infoArray.length; i++) {
      document.getElementById(`dropdown-area-item-div-${i}`).style.opacity =
        "0.35";
      document.getElementById(
        `dropdown-area-item-div-${i}`
      ).style.pointerEvents = "none";
    }
  };

  const allAreasHandleClick = () => {
    if (document.getElementById(`checkbox-all`).checked) {
      for (let i = 0; i < infoArray?.length; i++) {
        document.getElementById(`checkbox-project-${i}`).checked = false;
        setFilter([]);
      }
      enableDropDownItems();
      document.getElementById(`checkbox-all`).checked = false;
    } else {
      for (let i = 0; i < infoArray?.length; i++) {
        document.getElementById(`checkbox-project-${i}`).checked = true;
        let floorIds = [];
        infoArray?.map((floor) => floorIds.push(floor.id));
        setFilter(floorIds);
      }
      disableDropDownItems();
      document.getElementById(`checkbox-all`).checked = true;
    }
    document.getElementById(`log-book-checkbox`).checked = false;
    // setImagesSelectedCheckBox([]);
  };

  const handleClickCheckBox = (value, key) => {
    if (document.getElementById(`checkbox-project-${key}`).checked) {
      setFilter(filter.filter((floor) => floor !== value));
      document.getElementById(`checkbox-all`).checked = false;
      document.getElementById(`checkbox-project-${key}`).checked = false;
      enableDropDownItems();
    } else {
      setFilter([...filter, value]);
      if (filter.length + 1 === infoArray.length) {
        document.getElementById(`checkbox-all`).checked = true;
        disableDropDownItems();
      }
      // setFloorName("Levels selected: " + (filter.length + 1));
      document.getElementById(`checkbox-project-${key}`).checked = true;
    }
    document.getElementById(`log-book-checkbox`).checked = false;
    // setImagesSelectedCheckBox([]);
  };

  const changeStyleItemTagDiv = (action, key) => {
    if (action === "hover") {
      document.getElementById(`checkbox-floor-div-${key}`).style.background =
        "#F1F3F6";
    } else if (action === "leave") {
      document.getElementById(`checkbox-floor-div-${key}`).style.background =
        "white";
    }
  };

  return (
    <div className="z-10" ref={wrapperRef}>
      <div className="w-60">
        <div
          onClick={() => setIsList(!isList)}
          className="z-10 w-fit pt-3 pb-1 rounded bg-white text-xxsmall-bold  flex items-center cursor-pointer"
          style={{
            border: isList ? "1px solid #F1F3F6" : "1px solid transparent",
          }}
        >
          <span className="pl-1 text-colorBgPrimary">{floorName}</span>
          <div>
            {isList ? (
              <div className="z-10 pl-3 pr-2 pb-1">
                <img src="/assets/up.svg" alt="close" className="" />
              </div>
            ) : (
              <div className="z-10 pl-3 pr-2">
                <img src="/assets/down.svg" alt="open" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isList && (
        <div className="z-10 w-60 mt-3 py-3 px-2 shadow rounded h-60 overflow-y-scroll dropdown-list overflow-styles">
          <div
            id={`checkbox-floor-div-all`}
            className="z-10 flex items-center justify-between py-3 dropdown-projects-item cursor-pointer"
            onMouseOver={() => changeStyleItemTagDiv("hover", "all")}
            onMouseLeave={() => changeStyleItemTagDiv("leave", "all")}
            onClick={allAreasHandleClick}
          >
            <div className="pl-4 z-10 flex items-center w-full">
              <div className="z-10 flex items-center ">
                <div className="z-10 flex justify-center items-center relative">
                  <input
                    id={`checkbox-all`}
                    onClick={allAreasHandleClick}
                    type="checkbox"
                    className="z-20 checkbox absolute cursor-pointer w-3.5 h-3.5"
                  />
                </div>
              </div>
              <p className="w-full z-10 text-xxsmall mt-1 ml-5 text-colorBgPrimary">
                All areas
              </p>
            </div>
          </div>
          {infoArray?.map((item, key) => {
            return (
              <div
                id={`checkbox-floor-div-${key}`}
                key={key}
                className="z-10 flex items-center justify-between py-3 dropdown-projects-item cursor-pointer"
                style={{
                  border: infoArray.length === key + 1 && "0",
                  marginBottom: infoArray.length === key + 1 && 0,
                }}
                onMouseOver={() => changeStyleItemTagDiv("hover", key)}
                onMouseLeave={() => changeStyleItemTagDiv("leave", key)}
                onClick={() => handleClickCheckBox(item.id, key)}
              >
                <div
                  className="z-10 flex items-center w-full"
                  id={`dropdown-area-item-div-${key}`}
                >
                  <div className="pl-4 z-10  flex items-center w-full">
                    <div className="z-10 flex justify-center items-center relative">
                      <input
                        id={`checkbox-project-${key}`}
                        value={item.id}
                        onClick={() => handleClickCheckBox(item.id, key)}
                        type="checkbox"
                        className="z-20 checkbox absolute cursor-pointer w-3.5 h-3.5"
                      />
                    </div>
                    <p
                      // onClick={() => handleClickCheckBox(item.id, key)}
                      className="w-full z-10 text-xxsmall mt-1 ml-5 text-colorBgPrimary"
                    >
                      {item.name}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LogBookDropdownFloors;
